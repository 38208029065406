import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { auth0ReportApiAudience } from "./../AppSettings";
import ReportsLogin from "./ReportsLogin";
import { Button, Col, Form, Row } from "react-bootstrap";
import EventSelector from "./EventSelector";
import { EventReducerState } from "../store/reducers/EventsReducer";
import { ReportState } from "../store/reducers/ReportReducer";
import { RootState } from "../store/rootReducer";
import { loadEvents } from "../store/actions/EventsAction";
import { loadReport } from "../store/actions/ReportAction";
import { connect } from "react-redux";
import ReportDetailedRenderer from "./ReportDetailedRenderer";
import ReportSummaryRenderer from "./ReportSummaryRenderer";
import { EventGroupsReducerState } from "../store/reducers/EventGroupsReducer";
import { loadEventGroups } from "../store/actions/EventGroupsAction";

interface ReportStateProps {
    eventsState: EventReducerState;
    reportState: ReportState;
    eventGroupsState: EventGroupsReducerState;
}

interface ReportDispatchProps {
    loadEvents: (accessToken: string) => void;
    loadReport: (accessToken: string, eventIds: number[]) => void;
    loadEventGroups: (accessToken: string) => void;
}

type ReportProps = ReportStateProps & ReportDispatchProps;

export function Reports(props: ReportProps) {

    const { isAuthenticated, getAccessTokenSilently, isLoading } = useAuth0();
    const [accessToken, setAccessToken] = useState<string>();
    const [selectedEventIds, setSelectedEventIds] = useState<number[]>([]);

    useEffect(() => {
        if (isAuthenticated && !isLoading) {
            retrieveUserToken();
        }
    }, [isLoading, isAuthenticated]);

    const retrieveUserToken = async () => {
        const token = await getAccessTokenSilently({
            audience: auth0ReportApiAudience
        });

        setAccessToken(token);
        props.loadEventGroups(token);
    }

    const onSelectedEventsChanged = (eventIds: number[]) => {
        setSelectedEventIds(eventIds);
    }

    const onLoadReportClick = () => {
        if (selectedEventIds)
            props.loadReport(accessToken as string, selectedEventIds);
    }

    const renderReports = () => {
        return (<div>
            <Row>
                <Col sm={12} md={8}>
                    {props.eventsState.events ? <EventSelector 
                        eventGroups={props.eventGroupsState.eventGroups}
                        onSelectedEventsChanged={onSelectedEventsChanged} /> :
                        <React.Fragment />}
                </Col>
                <Col sm={12} md={4}>
                    <Button variant="primary" onClick={onLoadReportClick}>Load Report</Button>
                </Col>
            </Row>
            <Row className="mt-5">
                <Col sm={12} md={12}>
                    {props.reportState.report ? <ReportSummaryRenderer items={props.reportState.report.summary} /> :
                        <ReportSummaryRenderer items={[]} />}
                </Col>
            </Row>
            <Row className="mt-5">
                <Col sm={12} md={12}>
                    {props.reportState.report ? <ReportDetailedRenderer items={props.reportState.report.items} /> :
                        <ReportDetailedRenderer items={[]} />}
                </Col>
            </Row>
        </div>)
    }

    return (isAuthenticated ? renderReports() : <ReportsLogin />);
}

const mapStateToProps = (state: RootState): ReportStateProps => {
    return {
        eventsState: state.events,
        reportState: state.report,
        eventGroupsState: state.eventGroups
    }
}

const mapDispatchToProps = (dispatch: any): ReportDispatchProps => {
    return {
        loadEvents: (accessToken: string) => dispatch(loadEvents(accessToken)),
        loadReport: (accessToken: string, eventIds: number[]) => dispatch(loadReport(accessToken, eventIds)),
        loadEventGroups: (accessToken: string) => dispatch(loadEventGroups(accessToken))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(Reports);