import Axios from "axios";
import React from "react";
import { useEffect } from "react";
import { Alert, Card, Jumbotron, Spinner } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, withRouter } from "react-router-dom";
import { apiBaseUrl } from "./AppSettings";
import { setEventGroupName } from "./store/actions/UIStateActions";

interface ConfirmOrderResponse {
    fullName: string;
}

interface Props {
    updateEventGroupName: (eventGroupName: string) => void;
}

function SquareOrderConfirmation(props: Props) {

    enum UiState {
        initial,
        validationError,
        confirmInProgress,
        confirmError,
        confirmSuccess
    }

    const [checkoutId, setCheckoutId] = React.useState<string | null>(null);
    const [uiState, setUiState] = React.useState<UiState>(UiState.initial);
    const [errorMessage, setErrorMessage] = React.useState<string | null>(null);
    const [donorName, setDonorName] = React.useState<string | null>(null);
    const [eventGroupName, setEventGroupName] = React.useState<string | null>(null);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(window.location.search);
        const transactionId = urlSearchParams.get("transactionId");
        const checkoutId = urlSearchParams.get("checkoutId");
        
        const eventGroupName = urlSearchParams.get("event-group-name") ?? "";
        props.updateEventGroupName(eventGroupName);
        setEventGroupName(eventGroupName);

        if (!transactionId || !checkoutId) {
            setUiState(UiState.validationError);
            setErrorMessage("Missing transactionId or checkoutId");
            return;
        }

        setCheckoutId(checkoutId);
        setUiState(UiState.confirmInProgress);
        confirmOrder(checkoutId, transactionId);
    }, []);

    const confirmOrder = async (checkoutId: string, transactionId: string) => {
        try {
            const response = await Axios.put<ConfirmOrderResponse>(`${apiBaseUrl}/api/SquareOrders/confirm-order`, {
                checkoutId,
                transactionId
            });
            setDonorName(response.data.fullName);
            setUiState(UiState.confirmSuccess);
        } catch (ex) {
            setUiState(UiState.confirmError);
            setErrorMessage(`Error confirming checkout ${checkoutId}. Error message is - 
            ${ex}.`)
        }
    }

    const renderInProgress = () => {
        return (<Alert key="paymentInProgress" variant="primary">
            <h3>Please wait while we process the order #{checkoutId} <Spinner animation="grow" /></h3>
        </Alert>);
    }

    const renderValidationError = () => {
        return (<Alert key="paymentError" variant="danger">
            <h3>Error - {errorMessage}</h3>
        </Alert>);
    }

    const renderKidsDiwaliMessage = () => {
        return (eventGroupName === "sydney-kids-diwali") ? 
        <p>A confirmation QR code will be emailed to you in the near future.</p> :
        <React.Fragment />
    }

    const renderSuccess = () => {
        return (<>
            <h1>Jai Swaminarayan {donorName},</h1>
            <p>
                Thank you for the payment. Your payment request is successfully processed. You will receive the receipt of your payment from Square Pay shortly.
            </p>
            {renderKidsDiwaliMessage()}
        </>)
    }

    const renderUI = () => {
        switch (uiState) {
            case UiState.confirmError:
            case UiState.validationError:
                {
                    return renderValidationError();
                }
            case UiState.confirmInProgress:
                {
                    return renderInProgress();
                }
            case UiState.confirmSuccess:
                {
                    return renderSuccess();
                }
        }
    }

    const renderReturnLink = (eventGroupName: string | null) => {
        return eventGroupName === "canberra-mandir-murti-pratistha" ? (canberraUtsavLink) : (eventGroupLink);
    }

    const canberraUtsavLink = <a href="https://cbr.bapsanz.org/thanks" target="_self" className="btn btn-danger">Return</a>;
    const eventGroupLink = <Link to={`/${eventGroupName}`} className="btn btn-danger">Return</Link>;

    return (<div>
        <Jumbotron>
            {renderUI()}
            <p>
                {renderReturnLink(eventGroupName)}
            </p>
        </Jumbotron>
    </div>)
}

const mapStateToProps = (state: any) => {
    return {
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(SquareOrderConfirmation)); ;