import Axios from "axios";
import { FieldArray, Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Row, Col, Card, Alert } from "react-bootstrap";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import * as Yup from "yup";
import { apiBaseUrl } from "./AppSettings";
import Centre from "./models/Centre";
import CustomField from "./models/CustomField";
import CustomFieldValue from "./models/CustomFieldValue";
import Event from "./models/Event";
import { updateSevaDetailsForEvent } from "./store/actions/DonationDetailsActions";
import DonorDetails from "./store/actions/DonorDetails";
import { updateDonorDetails } from "./store/actions/DonorDetailsActions";
import { setEventGroupName } from "./store/actions/UIStateActions";

// https://medium.com/@deshan.m/yup-formik-dynamic-array-of-object-form-validation-ed0afed3d886
interface KidInformation {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    monthOfBirth: string;
    yearOfBirth?: number;
}

const blankKidInformation: KidInformation = {
    firstName: "",
    lastName: "",
    middleName: "",
    gender: "",
    monthOfBirth: "",
    yearOfBirth: undefined
};

interface KidsDiwaliRegistrationFormProps {
    firstName: string;
    middleName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    streetAddress: string;
    suburb: string;
    zip: string;
    numberOfAdults: number | undefined;
    slot: string;
    participatingKids: KidInformation[];
}

const initialValues: KidsDiwaliRegistrationFormProps = {
    firstName: "",
    middleName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    streetAddress: "",
    suburb: "",
    zip: "",
    slot: "",
    numberOfAdults: undefined,
    participatingKids: []
}

interface StateProps {
    donor: any;
    uiState: any;
}

interface DispatchProps {
    updateDonorDetails: (donorDetails: DonorDetails) => void;
    updateEventGroupName: (eventGroupName: string) => void;
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event) => void;
}

type ComponentProps = StateProps & DispatchProps;

export function KidsDiwaliRegistration(props: ComponentProps) {

    const [events, setEvents] = React.useState<Array<Event>>([]);
    const [allDetailsEntered, setAllDetailsEntered] = React.useState<boolean>(false);

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        middleName: Yup.string().required().max(50),
        mobileNumber: Yup.string().matches(/04([0-9]){8}\b/gm, "Please enter mobile number in 04xxxxxxxx").required(),
        email: Yup.string().email().required(),
        streetAddress: Yup.string().required(),
        suburb: Yup.string().required(),
        zip: Yup.number().required(),
        numberOfAdults: Yup.number().required().positive(),
        slot: Yup.string().required(),
        participatingKids: Yup.array().of(
            Yup.object().shape({
                firstName: Yup.string().required().max(50),
                lastName: Yup.string().required().max(50),
                yearOfBirth: Yup.number().required().min(2004),
                monthOfBirth: Yup.string().required(),
                gender: Yup.string().required()
            })
        ).min(1, "Atleast 1 participating kid needed for the registration")
    });

    useEffect(() => {
        props.updateEventGroupName("sydney-kids-diwali-2023");
        loadEvents();
    }, []);

    const loadEvents = async () => {
        const response = await Axios.get<Array<Event>>(`${apiBaseUrl}/api/events?eventGroupName=sydney-kids-diwali-2023`);
        const events = response.data;
        setEvents(events);
    }

    const onSubmit = (values: KidsDiwaliRegistrationFormProps) => {
        console.log(values);

        props.updateDonorDetails({
            firstName: `${values.firstName} ${values.middleName}`,
            centre: Centre.Sydney,
            email: values.email,
            lastName: values.lastName,
            mobile: values.mobileNumber,
        });

        // Adult Pass
        const adultPassEvent = events.find(evt => evt.name == "Adults-Pass") as Event;

        const addressCustomField = adultPassEvent?.customFields.find(cf => cf.label === "Address");
        const addressValue: CustomFieldValue = {
            customFieldId: addressCustomField!.id,
            customFieldValue: JSON.stringify({ streetAddress: values.streetAddress, suburb: values.suburb, zip: values.zip })
        };

        const slotCustomField = adultPassEvent!.customFields.find(cf => cf.label === "Slot");
        const slotValue: CustomFieldValue = {
            customFieldId : slotCustomField!.id,
            customFieldValue: values.slot
        };

        const parsedNumberOfAdults = Number.parseInt(values.numberOfAdults!.toString());
        props.updateSevaDetailsForEvent(parsedNumberOfAdults, [addressValue, slotValue], adultPassEvent);

        // Kids Pass
        const kidsPassEvent = events.find(evt => evt.name == "Kids-Pass") as Event;
        const kidsInfoCustomAttribute = kidsPassEvent?.customFields[0] as CustomField;
        const kidsInfoWithId = values.participatingKids.map((eachKid, index) => {
            const kidWithId = { ...eachKid, id: index };
            return kidWithId;
        });
        const kidsInfo = JSON.stringify(kidsInfoWithId);
        const kidsInfoValue: CustomFieldValue = { customFieldId: kidsInfoCustomAttribute.id, customFieldValue: kidsInfo };
        props.updateSevaDetailsForEvent(values.participatingKids.length,
            [kidsInfoValue],
            kidsPassEvent);

        setAllDetailsEntered(true);
    }

    const renderBirthYears = () => {
        const birthYears: JSX.Element[] = [];

        birthYears.push((<option></option>));
        for (var ctr = 2022; ctr >= 2004; ctr--) {
            birthYears.push((<option>{ctr}</option>));
        }

        return birthYears;
    }

    const renderBirthMonths = () => {
        const birthMonths: JSX.Element[] = [];

        birthMonths.push((<option></option>));
        birthMonths.push((<option>January</option>));
        birthMonths.push((<option>February</option>));
        birthMonths.push((<option>March</option>));
        birthMonths.push((<option>April</option>));
        birthMonths.push((<option>May</option>));
        birthMonths.push((<option>June</option>));
        birthMonths.push((<option>July</option>));
        birthMonths.push((<option>August</option>));
        birthMonths.push((<option>September</option>));
        birthMonths.push((<option>October</option>));
        birthMonths.push((<option>November</option>));
        birthMonths.push((<option>December</option>));

        return birthMonths;
    }

    const renderParticipatingKidsForm = (formikProps: FormikProps<KidsDiwaliRegistrationFormProps>) => {
        return (<Card>
            <Card.Body>
                {console.log(formikProps.errors)}
                <Card.Title>Participating Kids Details ($5 Each)</Card.Title>
                <FieldArray name="participatingKids" render={arrayHelper => (
                    <div>
                        {(formikProps.values.participatingKids.map((eachParticipatingKid, index) => (
                            <React.Fragment>
                                <Row>
                                    <Col md={4} sm={12}>
                                        <Form.Group>
                                            <Form.Label>First Name</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Enter first name"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.firstName}
                                                name={`participatingKids[${index}].firstName`} />
                                            <Form.Text className="text-muted">
                                                First name.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.firstName ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].firstName}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Middle Name</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Enter middle name"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.middleName}
                                                name={`participatingKids[${index}].middleName`} />
                                            <Form.Text className="text-muted">
                                                Middle name.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.middleName ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].middleName}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                    <Col md={4} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Last Name</Form.Label>
                                            <Form.Control type="text"
                                                placeholder="Enter last name"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.lastName}
                                                name={`participatingKids[${index}].lastName`} />
                                            <Form.Text className="text-muted">
                                                Last name.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.lastName ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].lastName}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                </Row>
                                <Row>
                                    <Col md={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Year Of Birth</Form.Label>
                                            <select className="form-control"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.yearOfBirth}
                                                name={`participatingKids[${index}].yearOfBirth`} >
                                                {renderBirthYears()}
                                            </select>
                                            <Form.Text className="text-muted">
                                                Year of birth.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.yearOfBirth ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].yearOfBirth}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Month Of Birth</Form.Label>
                                            <select className="form-control"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.monthOfBirth}
                                                name={`participatingKids[${index}].monthOfBirth`} >
                                                {renderBirthMonths()}
                                            </select>
                                            <Form.Text className="text-muted">
                                                Month of birth.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.monthOfBirth ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].monthOfBirth}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Gender</Form.Label>
                                            <select className="form-control"
                                                onChange={formikProps.handleChange}
                                                value={eachParticipatingKid.gender}
                                                name={`participatingKids[${index}].gender`} >
                                                <option></option>
                                                <option value="female">She/her</option>
                                                <option value="male">He/him</option>
                                            </select>
                                            <Form.Text className="text-muted">
                                                Gender.
                                            </Form.Text>
                                            {formikProps.touched &&
                                                formikProps.touched.participatingKids &&
                                                formikProps.errors.participatingKids &&
                                                //@ts-ignore
                                                formikProps.errors.participatingKids[index]?.gender ?
                                                //@ts-ignore
                                                (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids[index].gender}</div>) : (<React.Fragment />)}
                                        </Form.Group>
                                    </Col>
                                    <Col md={3} sm={12}>
                                        <Form.Group>
                                            <Form.Label>Remove Entry</Form.Label>
                                            <Button variant="danger"
                                                className="form-control"
                                                type="button"
                                                size="sm"
                                                onClick={() => arrayHelper.remove(index)}>
                                                Remove
                                            </Button>
                                        </Form.Group>
                                    </Col>
                                </Row>
                            </React.Fragment>
                        )))}
                        <Row>
                            <Col md={4} sm={12}>
                                <Button variant="primary" type="button" size="sm" onClick={() => arrayHelper.push({ ...blankKidInformation })}>Add Participating Child</Button>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={12} sm={12}>
                            {formikProps.touched &&
                             formikProps.touched.participatingKids &&
                             formikProps.errors.participatingKids &&
                             //@ts-ignore
                             formikProps.errors.participatingKids &&
                             !Array.isArray(formikProps.errors.participatingKids) ?
                             //@ts-ignore
                             (<div className="fade alert alert-danger show">{formikProps.errors.participatingKids}</div>) : (<React.Fragment />)}
                            </Col>
                        </Row>
                    </div>
                )} />
            </Card.Body>
        </Card>);
    }

    const renderParticipatingAdultsForm = (formikProps: FormikProps<KidsDiwaliRegistrationFormProps>) => {
        return (<Card>
            <Card.Body>
                <Card.Title>Number of Adults ($5 Each)</Card.Title>
                <Row>
                    <Col md={4} sm={12}>
                        <Form.Group>
                            <Form.Label>Select Number of Adults Participating in event</Form.Label>
                            <select className="form-control"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.numberOfAdults}
                                name="numberOfAdults">
                                <option>-</option>
                                {
                                    Array.from({ length: 10 }).map((value, index) =>
                                        (<option>{index + 1}</option>)
                                    )
                                }
                            </select>

                            <Form.Text className="text-muted">
                                Number Of Adults.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.numberOfAdults &&
                                formikProps.errors.numberOfAdults ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.numberOfAdults}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md="4" sm={12}>
                        <Form.Group>
                            <Form.Label>Select the slot</Form.Label>
                            <select className="form-control"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.slot}
                                placeholder="Select the slot"
                                name="slot">
                                <option></option>
                                <option>12:30pm to 3:30pm</option>
                                <option>3:30pm to 7:00pm</option>
                            </select>

                            <Form.Text className="text-muted">
                                Slot of the event.
                            </Form.Text>

                            {formikProps.touched &&
                                formikProps.touched.slot &&
                                formikProps.errors.slot ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.slot}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>
            </Card.Body>
        </Card>
        );
    }

    const renderForm = () => (<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
    >
        {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                {/* console.log(JSON.stringify(formikProps.errors)) */}
                <Row>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter first name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.firstName}
                                name="firstName" />
                            <Form.Text className="text-muted">
                                Please enter first name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.firstName &&
                                formikProps.errors.firstName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formMiddleName">
                            <Form.Label>Middle Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter middle name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.middleName}
                                name="middleName" />
                            <Form.Text className="text-muted">
                                Please enter middle name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.middleName &&
                                formikProps.errors.middleName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.middleName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter last name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.lastName}
                                name="lastName" />
                            <Form.Text className="text-muted">
                                Please enter last name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.lastName &&
                                formikProps.errors.lastName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your email id
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.email &&
                                formikProps.errors.email ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile number</Form.Label>
                            <Form.Control type="text"
                                placeholder="04XXXXXXXX"
                                pattern="0[0-9]{9}"
                                name="mobileNumber"
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your mobile number starting with 04.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.mobileNumber &&
                                formikProps.errors.mobileNumber ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Address"
                                name="streetAddress"
                                value={formikProps.values.streetAddress}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your address
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.streetAddress &&
                                formikProps.errors.streetAddress ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.streetAddress}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formSubub">
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Suburb"
                                name="suburb"
                                value={formikProps.values.suburb}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your suburb
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.suburb &&
                                formikProps.errors.suburb ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.suburb}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formZip">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter ZipCode"
                                name="zip"
                                value={formikProps.values.zip}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter ZipCode
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.zip &&
                                formikProps.errors.zip ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.zip}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>
                <br />
                {renderParticipatingAdultsForm(formikProps)}
                <br />
                {renderParticipatingKidsForm(formikProps)}
                <br />
                <Row>
                    <Col md={4} sm={12}>
                        <Button variant="primary" type="submit" size="lg">
                            NEXT
                        </Button>
                    </Col>
                </Row>

            </Form>
        )}

    </Formik>);

    const renderRedirect = () => (<Redirect to="/confirm" />);

    const renderRegistrationClosedMessage = () => (
        <Alert variant="warning" style={{fontSize:20}}>
            <Alert.Heading>Jai Swaminarayan Dear Visitor</Alert.Heading>
            <p>
                Online registration for the Kids Diwali is closed now. <br/>
                <b>But you may register and participate at the Venue as well on Tuesday, 25th Oct for the following slot. </b>
            </p>
            <p>
                <table style={{border:'1'}}>
                    <thead>
                        <tr>
                            <th>Slot</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>12:00 PM - 3:30 PM</td>
                        </tr>
                        <tr>
                            <td>3:30 PM - 7:00 PM</td>
                        </tr>
                    </tbody>
                </table>
            </p>
            <hr />
            <p className="mb-0">
                
            </p>
        </Alert>)

    return (<div>
        {/*renderRegistrationClosedMessage()*/}
        {allDetailsEntered ? renderRedirect() : renderForm()}
    </div>)
}

const mapStateToProps = (state: any): StateProps => {
    return {
        donor: state.donor,
        uiState: state.uiState
    };
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        updateDonorDetails: (donorDetails: DonorDetails) => dispatch(updateDonorDetails(donorDetails)),
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
        updateSevaDetailsForEvent: (
            sevaCount: number,
            customFieldValues: CustomFieldValue[],
            event: Event) =>
            dispatch(updateSevaDetailsForEvent(sevaCount, customFieldValues, event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KidsDiwaliRegistration);
