import Axios from "axios";
import { Formik } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { apiBaseUrl } from "./AppSettings";
import Centre from "./models/Centre";
import CustomField from "./models/CustomField";
import CustomFieldValue from "./models/CustomFieldValue";
import Event from "./models/Event";
import { updateSevaDetailsForEvent } from "./store/actions/DonationDetailsActions";
import DonorDetails from "./store/actions/DonorDetails";
import { updateDonorDetails } from "./store/actions/DonorDetailsActions";
import { setEventGroupName } from "./store/actions/UIStateActions";

interface SdyneyMandirDonationsRegistrationFormProps {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    streetAddress: string;
    zip: string
    country: string;
    center: number;
    sevaAmount: number;
}

const initialValues: SdyneyMandirDonationsRegistrationFormProps = {
    firstName: "",
    lastName: "",
    center: 0,
    email: "",
    mobileNumber: "",
    streetAddress: "",
    zip: "",
    country: "",
    sevaAmount: 0
}

interface StateProps {
    donor: any;
    uiState: any;
}

interface DispatchProps {
    updateDonorDetails: (donorDetails: DonorDetails) => void;
    updateEventGroupName: (eventGroupName: string) => void;
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
}

type ComponentProps = StateProps & DispatchProps;

export function SydneyMandirDonationsRegistration(props: ComponentProps) {

    const [events, setEvents] = React.useState<Array<Event>>([]);
    const [allDetailsEntered, setAllDetailsEntered] = React.useState<boolean>(false);

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        mobileNumber: Yup.string().matches(/^\+(?:[0-9]●?){6,14}[0-9]$/, "Please enter mobile number in +61410456770 Or +15417543010").required(),
        email: Yup.string().email().required(),
        country: Yup.string().required().max(255),
        streetAddress: Yup.string().required().max(255),
        zip: Yup.number().required().min(1, "Zipcode of suburb is required"),
        sevaAmount: Yup.number().required().min(1)
    });

    useEffect(() => {
        props.updateEventGroupName("sydney-mandir-donations");
        loadEvents();
    }, []);

    const loadEvents = async () => {
        const response = await Axios.get<Array<Event>>(`${apiBaseUrl}/api/events?eventGroupName=sydney-mandir-donations`);
        const events = response.data;
        setEvents(events);
    }

    const onSubmit = (values: SdyneyMandirDonationsRegistrationFormProps) => {
        props.updateDonorDetails({
            firstName: values.firstName,
            centre: Centre.Sydney,
            email: values.email,
            lastName: values.lastName,
            mobile: values.mobileNumber,
        });

        const mappedCustomFields = mapCustomFields(events[0], values);

        props.updateSevaDetailsForEvent(1,
            mappedCustomFields,
            events[0],
            parseFloat(values.sevaAmount.toString()));

        setAllDetailsEntered(true);
    }

    const mapCustomFields = (
        selectedEvent: Event,
        values: SdyneyMandirDonationsRegistrationFormProps) => {

        const mappedCustomFields = new Array<CustomFieldValue>();

        const zipCustomField = GetCustomField("Zip", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: zipCustomField.id,
                customFieldValue: values.zip
            });

        const streetAddressCustomField = GetCustomField("StreetAddress", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: streetAddressCustomField.id,
                customFieldValue: values.streetAddress
            });

        const countryAddressCustomField = GetCustomField("Country", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: countryAddressCustomField.id,
                customFieldValue: values.streetAddress
            });

        return mappedCustomFields;
    }

    const GetCustomField = (name: string, event: Event) => {
        return event
            .customFields
            .find(cf => cf.label === name) as CustomField;
    }

    const renderCentreSelect = (): Array<JSX.Element> => {
        const optionsForCentres = new Array<JSX.Element>();

        optionsForCentres.push(<option key={`Centre-${Centre.Sydney}`} value={Centre.Sydney}>Sydney</option>);

        return optionsForCentres;
    }

    const renderForm = () => (<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
    >
        {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                <Row>
                    <Col md={4} sm={12}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter first name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.firstName}
                                name="firstName" />
                            <Form.Text className="text-muted">
                                Please enter your first name.
                             </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.firstName &&
                                formikProps.errors.firstName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>

                    <Col md={4} sm={12}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter last name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.lastName}
                                name="lastName" />
                            <Form.Text className="text-muted">
                                Please enter your last name.
                             </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.lastName &&
                                formikProps.errors.lastName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>
               
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your email id
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.email &&
                                formikProps.errors.email ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile number</Form.Label>
                            <Form.Control type="text"
                                placeholder="+61485215550 OR +15417543010"
                                name="mobileNumber"
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your mobile number starting with +[CountryCode][MobileNumber].
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.mobileNumber &&
                                formikProps.errors.mobileNumber ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Street Address</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Address"
                                name="streetAddress"
                                value={formikProps.values.streetAddress}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your address
                                </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.streetAddress &&
                                formikProps.errors.streetAddress ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.streetAddress}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formSubub">
                            <Form.Label>Zipcode</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter ZipCode"
                                name="zip"
                                value={formikProps.values.zip}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter ZipCode
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.zip &&
                                formikProps.errors.zip ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.zip}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formCountry">
                            <Form.Label>Country</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Country"
                                name="country"
                                value={formikProps.values.country}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter Country
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.zip &&
                                formikProps.errors.zip ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.zip}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={3} sm={12}>
                        <Form.Group controlId="formAddress">
                            <Form.Label>Donation Amount (AUD)</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Donation Amount"
                                name="sevaAmount"
                                value={formikProps.values.sevaAmount}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter donation amount
                                </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.sevaAmount &&
                                formikProps.errors.sevaAmount ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.sevaAmount}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Button variant="primary" type="submit" size="lg">
                    NEXT
                </Button>
            </Form>
        )}

    </Formik>);

    const renderRedirect = () => (<Redirect to="/confirm" />);

    return (<div>
        {allDetailsEntered ? renderRedirect() : renderForm()}
    </div>)
}

const mapStateToProps = (state: any): StateProps => {
    return {
        donor: state.donor,
        uiState: state.uiState
    };
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        updateDonorDetails: (donorDetails: DonorDetails) => dispatch(updateDonorDetails(donorDetails)),
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
        updateSevaDetailsForEvent: (
            sevaCount: number,
            customFieldValues: CustomFieldValue[],
            event: Event,
            sevaAmount?: number) =>
            dispatch(updateSevaDetailsForEvent(sevaCount, customFieldValues, event, sevaAmount))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(SydneyMandirDonationsRegistration);