import DonationForEvent from "../actions/DonationForEvent";
import { UPDATE_SEVA_COUNT_FOR_EVENT } from "../actions/DonationDetailsActions";
import Event from "../../models/Event";
import CustomFieldValue from "../../models/CustomFieldValue";

export interface DonationDetailsReducerState {
    donations: Array<DonationForEvent>,
    totalSevaAmount: number
}

const initialState: DonationDetailsReducerState = {
    donations: [],
    totalSevaAmount: 0
};

type UpdateDonationAction = {
    eventName: string,
    data: {
        event: Event,
        sevaCount: number,
        customFieldValues: CustomFieldValue[],
        sevaAmount?: number
    }
};

export const DonationDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_SEVA_COUNT_FOR_EVENT: {
            const copyOfState: DonationDetailsReducerState = { ...state };
            const updateAction = action as UpdateDonationAction;

            if (!copyOfState.donations)
                copyOfState.donations = [];

            const indexOfEvent = copyOfState
                .donations
                .findIndex(r => r.eventId === updateAction.data.event.id);

            if(indexOfEvent === -1 && updateAction.data.sevaCount === 0)
                return copyOfState;
           
            if (indexOfEvent === -1 && updateAction.data.sevaCount >= 1) {
                copyOfState.donations.push(
                    {
                        eventId: updateAction.data.event.id,
                        sevaCount: updateAction.data.sevaCount,
                        eachSevaAmount: updateAction.data.event.sevaAmount,
                        customFields: updateAction.data.customFieldValues,
                        sevaAmount: updateAction.data.sevaAmount
                    });
            } 
            else if (indexOfEvent !== -1 && updateAction.data.sevaCount <= 0) {
                copyOfState.donations.splice(indexOfEvent, 1);
            }
            else {
                const existing = copyOfState.donations[indexOfEvent];
                existing.sevaCount = updateAction.data.sevaCount;
                existing.customFields = [...updateAction.data.customFieldValues]
                existing.sevaAmount = updateAction.data.sevaAmount;
            }

            copyOfState.totalSevaAmount = getTotalSevaAmount(copyOfState.donations);
            return copyOfState;
        }
        default: {
            return state;
        }
    }
}

const getTotalSevaAmount = (allDonations: DonationForEvent[]) : number => {
    let totalAmount: number = 0;

    allDonations.forEach(eachDonation => {
        totalAmount += eachDonation.sevaAmount ?? (eachDonation.sevaCount * eachDonation.eachSevaAmount);
    });

    return totalAmount;
}