import React from "react";
import { Card, Container, Row, Form, Col, Badge } from "react-bootstrap";
import { DonationEventRendererProps } from "../DonationEventRenderer";
import CustomFieldValue from "../models/CustomFieldValue";

export default function KiteFestivalCarRegoEventRenderer(props: DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(-1);
    const [regoNumbers, setRegoNumbers] = React.useState<string[]>(new Array(props.event.maxCount));

    const onSelectedCountChanged = (event: any) => {
        const parsedSevaCount = parseInt(event.target.value);
        clearRegosBeyondSevaCount(parsedSevaCount);
        setSelectedSevaCount(parsedSevaCount);
        raiseUpdatedEvent(parsedSevaCount);
    }

    const clearRegosBeyondSevaCount = (sevaCount: number) => {
        for (let index:number = sevaCount; index < props.event.maxCount; index++) {
            delete regoNumbers[index];
        }
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        const customFieldValues: CustomFieldValue[] = [];
        const nonNullRegoNumbers = getNonNullRegoNumbers();

        if (regoNumbers && regoNumbers.length > 0) {
            customFieldValues.push({
                customFieldId: props.event.customFields[0].id,
                customFieldValue: JSON.stringify(nonNullRegoNumbers)
            });
        }

        props.updateSevaDetailsForEvent(
            sevaCount,
            customFieldValues,
            props.event);
    }

    const getNonNullRegoNumbers = () => {
        const nonNullRegoNumbers = new Array<string>();

        for (let index = 0; index < regoNumbers.length; index++) {
            if (regoNumbers[index])
                nonNullRegoNumbers.push(regoNumbers[index]);
        }

        return nonNullRegoNumbers;
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderSevaCount = () => {
        const sevaCountOptionElement: JSX.Element[] = [];

        for (let counter = 1; counter <= props.event.maxCount; counter++) {
            sevaCountOptionElement.push((<option key={`SevaCount-${counter}`} value={counter}>{counter}</option>))
        }

        return sevaCountOptionElement;
    }

    const renderRegoNumberControls = (): JSX.Element => {
        if (selectedSevaCount <= 0)
            return (<React.Fragment />);

        return (<Form.Group>
            <Form.Label><b>Enter the Rego Number of All Cars</b></Form.Label>
            <Row>
                {renderRegoNumbers()}
            </Row>
        </Form.Group>);
    }

    const handleRegoNumberChange = (index: number, newValue: string) => {
        regoNumbers[index] = newValue;
        raiseUpdatedEvent(selectedSevaCount);
    }

    const renderRegoNumbers = () : Array<JSX.Element> => {
        const elements: Array<JSX.Element> = new Array<JSX.Element>();

        for (let counter = 0; counter < selectedSevaCount; counter++) {
            elements.push(<Col sm={12} md={4}>
                <Form.Control type="text"
                            style={{marginTop: "5px"}}
                            key={`rego_number${counter}`} 
                            value={regoNumbers[counter]}
                            onChange={e => handleRegoNumberChange(counter, e.target.value)} />
            </Col>);
        }

        return elements;
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text" style={{ fontWeight: "bold", background: "none" }}
                                    value={`$${props.event.sevaAmount}`}
                                    readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Select the count</Form.Label>
                                <Form.Control as="select"
                                    value={selectedSevaCount}
                                    onChange={onSelectedCountChanged}>
                                    <option value={-1}>Select count</option>
                                    {renderSevaCount()}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12}>
                            {renderRegoNumberControls()}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}