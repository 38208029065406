import React from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

interface FooterProps {
    eventGroupName: string,
    match: any;
    location: any;
    history: any;
}

function Footer(props: FooterProps) {

    let footerImage: string = "";

    switch (props.eventGroupName) {
        case "canberra-mandir-seva":
        case "canberra-mandir-murti-pratistha":
            {
                footerImage = `/${props.eventGroupName}-footer.jpeg`;
                break;
            }
        default:
            {
                footerImage = "/footer.jpeg";
            }
    }

    const renderFooter = (eventGroupName: string) : boolean => {
        return true;
    }

    return (renderFooter(props.eventGroupName) ? (<div style={{ marginTop: 20 }}>
        <img src={footerImage} width="100%" />
    </div>) : (<React.Fragment />));
}

const mapStateToProps = (state: any) => {
    return {
        eventGroupName: state.uiState.eventGroupName
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {}
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Footer));