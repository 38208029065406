import React, { useEffect } from "react";
import ReportSummaryItem from "../models/ReportSummaryItem";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { Button, Card } from "react-bootstrap";
import { ColDef, GridApi, GridOptions, ValueGetterParams } from "ag-grid-community";

interface ReportDetailedRendererProps {
    items: Array<ReportSummaryItem>
}

export default function ReportDetailedRenderer(props: ReportDetailedRendererProps) {

    const [gridApi, setGridApi] = React.useState<GridApi | null>(null);

    const gridOptions: GridOptions = {
        defaultColDef: {
            resizable: true
        },
        columnDefs: []
    };

    const onExportAsCsv = () => {
        gridApi?.exportDataAsCsv(undefined);
    }

    const onGridReady = (params: any) => {
        setGridApi(params.api);
    }

    useEffect(() => {
        if (!props.items || props.items.length <= 0)
            return;

        const commonColumns: Array<ColDef> = getCommonColumns();
        const customAttributeColumns: Array<ColDef> = getCustomAttributeFields();
        
        var allColDefs = new Array<ColDef>();
        allColDefs = allColDefs.concat(commonColumns, customAttributeColumns);

        gridApi?.setColumnDefs(allColDefs);

    }, [props.items]);

    const getCommonColumns = (): Array<ColDef> => {
        const columnFields = ["paymentGatewayTransactionId", "center",
            "eventName", "firstName", "lastName", "mobileNumber", "email",
            "sevaCount", "sevaAmount", "createdOn", "address", "suburb", "zip"];
        const columns = new Array<ColDef>();

        for (let colIndex = 0; colIndex < columnFields.length; colIndex++) {
            const eachColumn = columnFields[colIndex];
            columns.push({
                headerName: eachColumn,
                field: eachColumn,
                sortable: true,
                filter: true
            });
        }

        return columns;
    }

    const getCustomAttributeFields = (): Array<ColDef> => {
        const customAttributes = props.items[0].customAttributes;

        if (!customAttributes)
            return [];

        const customAttributeColumns = new Array<ColDef>();
        for (const eachKey in customAttributes) {
            const colDef: ColDef = {
                headerName: eachKey,
                sortable: true,
                filter: true,
                valueGetter: (params: ValueGetterParams) => {
                    const reportItem = params.data as ReportSummaryItem;
                    if (reportItem && reportItem.customAttributes)
                        return reportItem.customAttributes[eachKey];

                    return null;
                }
            };
            customAttributeColumns.push(colDef);
        }

        return customAttributeColumns;
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Detailed Report</Card.Title>
                <Card.Text>
                    <div className="ag-theme-balham" style={{ height: 500, width: "100%" }}>
                        <AgGridReact
                            gridOptions={gridOptions}
                            onGridReady={onGridReady}
                            rowData={props.items}>
                        </AgGridReact>
                    </div>
                </Card.Text>
                <Button onClick={onExportAsCsv}>Export as CSV</Button>
            </Card.Body>
        </Card>)
}
