import Axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "../../AppSettings";
import Event from "../../models/Event";

export const LOAD_EVENTS = "LOAD_EVENTS";
export const LOAD_EVENTS_START = "LOAD_EVENTS_START";
export const LOAD_EVENTS_SUCCESS = "LOAD_EVENTS_SUCCESS";

export interface LoadEventsStart {
    type: typeof LOAD_EVENTS_START;
}

export interface LoadEventsSuccess {
    type: typeof LOAD_EVENTS_SUCCESS;
    events: Array<Event>;
}

export type EventActions = LoadEventsStart | LoadEventsSuccess;

const createLoadEventsAction = (): LoadEventsStart => {
    return {
        type: LOAD_EVENTS_START
    };
}

const createLoadEventsSuccess = (events: Array<Event>): LoadEventsSuccess => {
    return {
        type: LOAD_EVENTS_SUCCESS,
        events
    };
}

export const loadEvents = (token: string) => async (dispatch: any) => {
    dispatch(createLoadEventsAction());

    const requestConfig: AxiosRequestConfig = {};
    requestConfig.headers = { 'Authorization': `Bearer ${token}` };

    const response = await Axios.get<Array<Event>>(`${apiBaseUrl}/api/admin/events`, requestConfig);
    dispatch(createLoadEventsSuccess(response.data));
}