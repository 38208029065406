import React from "react";
import {Card, Col, Container, Row} from "react-bootstrap";

export default function MelbourneMandirInstalmentRenderer() {

    return (<div style={{marginTop: "15px"}}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                Square Metre Seva (Instalments)
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="justify-content-md-center" style={{marginBottom: "15px", fontSize: "20px"}}>
                               <span> If you wish to pay seva amount in instalments, <br/>
                                please contact on
                                <span style={{fontWeight: "bold", fontStyle: "italic"}}> +61 433 728 185 </span>
                                or email at <span style={{
                                       fontWeight: "bold",
                                       fontStyle: "italic"
                                   }}>mels.accounts@au.baps.org</span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}
