import Event from "../../models/Event";
import { EventActions } from "../actions/EventsAction";

export interface EventReducerState {
    events: Array<Event>
}

const defaultState: EventReducerState = {
    events: []
}

export const EventReducer = (state = defaultState, action: EventActions): EventReducerState => {
    
    switch(action.type) {
        case "LOAD_EVENTS_START": {
            return {
                events: []
            };
        }
        case "LOAD_EVENTS_SUCCESS": {
            const updatedState = [...action.events];
            return {
                events: updatedState
            };
        }
    }
    
    return state;
}