import React, { useEffect } from "react";
import {Form, Button, Alert, Col, Row} from "react-bootstrap";
import {Formik, FormikProps} from "formik";
import { updateDonorDetails } from "./store/actions/DonorDetailsActions";
import { connect } from "react-redux";
import DonorDetails from "./store/actions/DonorDetails";
import { Redirect, withRouter } from "react-router-dom";
import Centre from "./models/Centre";
import * as Yup from "yup";
import { setCenter, setEventGroupName } from "./store/actions/UIStateActions";
import getCentersForEventGroup from "./EventGroupAllowedCenterMap";

export interface DonorDetailsProps {
    donor: DonorDetails;
    uiState: any;
    updateDonorDetails: (donorDetails: DonorDetails) => void;
    match: any;
    updateEventGroupName: (eventGroupName: string) => void;
    setCenter: (centerId: number) => void;
    acquireAddress? : boolean;
}

interface DonorDetailsFormProps {
    firstName: string;
    lastName: string;
    email: string;
    mobileNumber: string;
    centre: number;
    centerSetInUiState: boolean;
    address: string | undefined;
    suburb: string | undefined;
    zip: number | undefined;
}

function AcquireDonorDetails(props: DonorDetailsProps) {

    const [donorDetailsEntered, setDonorDetailEntered] = React.useState<boolean>(false);
    const [allowCenterSelection, setAllowCenterSelection] = React.useState<boolean>(true);
    const [eventNeedsDonorAddress, setEventNeedsDonorAddress] = React.useState<boolean>(false);

    useEffect(() => {
        props.updateEventGroupName(props.match.params.eventgroup);
        setCenterId();
        calcEventNeedsDonorAddress(props.match.params.eventgroup);
    }, []);

    const getEventGroupName = () => props.match?.params?.eventgroup;

    const calcEventNeedsDonorAddress = (eventGroupName: string) => {
        if(eventGroupName?.indexOf("annakut-2023") === 0) {
            setEventNeedsDonorAddress(true);
        }
    }

    const setCenterId = () => {
        const eventGroupName = getEventGroupName();

        if(eventGroupName?.indexOf("melbourne-south-mandir-seva") == 0)
        {
            props.setCenter(convertCenterToNumber("melbournemandir"));
            return;
        }
        else if(eventGroupName?.indexOf("syd-mandir-stg-1-seva-sydney") == 0 || eventGroupName?.indexOf("pramukh-cup-2024-payments") == 0)
        {
            props.setCenter(convertCenterToNumber("sydney"));
            return;
        }
        //const pathParts = props.match.params.eventgroup.split("-");
        //const centerId = convertCenterToNumber(pathParts[pathParts.length - 1]);
        //props.setCenter(centerId);
    }

    const convertCenterToNumber = (centerName: string): number => {

        switch (centerName) {
            case "sydney": return Centre.Sydney;
            case "adelaide": return Centre.Adelaide;
            case "brisbane": return Centre.Brisbane;
            case "canberra": return Centre.Canberra;
            case "darwin": return Centre.Darwin;
            case "goldCoast": return Centre.GoldCoast;
            case "melbournemandir": return Centre.MelbourneMandir;
            case "melbournewest": return Centre.MelbourneMandir;
            case "melbournesouth": return Centre.MelbourneMandir;
            case "perth": return Centre.Perth;
            case "hobart": return Centre.Hobart;
            case "sydney": return Centre.Sydney;
            case "griffith": return Centre.Griffith;
        }

        return -1;
    }

    const defaultFormValues: DonorDetailsFormProps = {
        firstName: props.donor.firstName,
        lastName: props.donor.lastName,
        mobileNumber: props.donor.mobile,
        email: props.donor.email,
        centre: props.donor.centre,
        centerSetInUiState: props.uiState.center !== null,
        address: undefined,
        suburb: undefined,
        zip: undefined
    }

    console.log(defaultFormValues);

    const donorDetailsSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        mobileNumber: Yup.string().matches(/0[0-9]{9,10}\b/gm, "Please enter mobile number in 04xxxxxxxx (AU)/ 02XXXXXXXXX").required(),
        email: Yup.string().email().required(),
        centerSetInUiState: Yup.boolean(),
        centre: Yup.number().when("centerSetInUiState", {
            is: false,
            then: Yup.number().required().min(1, "Center is required before submission")
        }),
        suburb: Yup.string().max(100),
        address: Yup.string().max(500),
        zip: Yup.number().max(9999)
    });

    const onNextClick = (values: DonorDetailsFormProps) => {
        props.updateDonorDetails({
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
            mobile: values.mobileNumber,
            centre: props.uiState.center !== null ? props.uiState.center : values.centre,
            address: values.address,
            suburb: values.suburb,
            zip: values.zip
        });

        setDonorDetailEntered(true);
    }

    const renderCentreSelect = (): Array<JSX.Element> => {
        const allowedCenters = getCentersForEventGroup(props.match.params.eventgroup);
        const optionsForCentres = new Array<JSX.Element>();

        allowedCenters.forEach(eachCenter => {
            const keyNumeric: number = eachCenter;

            if (isNaN(keyNumeric))
                return;

            optionsForCentres.push(<option key={`Centre-${keyNumeric}`} value={keyNumeric}>{Centre[keyNumeric]}</option>)
        });

        return optionsForCentres;
    }

    const renderAdditionalInfo = () => {
        if (props.match?.params?.eventgroup?.indexOf("ppl-2023-payment") !== 0)
            return <React.Fragment />;

        return (<Alert key="alertPpl2023Payments" variant="warning" style={{width:"80%",margin:"auto"}}>
            <ul>
                <li><b>Please note that you are entering following details for payment purposes</b></li>
                <li><b>Make sure that your name, email and mobile matches with the details entered during the registration</b></li>
                <li><b>These details will be used to consolidate the participants who have paid the participation fees</b></li>
            </ul>
        </Alert>);
    }

    const renderForm = (formikProps: FormikProps<DonorDetailsFormProps>) => {

        return (<Form onSubmit={formikProps.handleSubmit}>
            {renderAdditionalInfo()}
            <Form.Group controlId="formFirstName">
                <Form.Label>First Name</Form.Label>
                <Form.Control type="text"
                    placeholder="Enter first name"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.firstName}
                    name="firstName" />
                <Form.Text className="text-muted">
                    Please enter your first name.
                </Form.Text>
                {formikProps.touched &&
                    formikProps.touched.firstName &&
                    formikProps.errors.firstName ?
                    (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
            </Form.Group>

            <Form.Group controlId="formEmail">
                <Form.Label>Last Name</Form.Label>
                <Form.Control type="text"
                    placeholder="Enter last name"
                    name="lastName"
                    onChange={formikProps.handleChange}
                    value={formikProps.values.lastName} />
                <Form.Text className="text-muted">
                    Please enter your last name.
                </Form.Text>
                {formikProps.touched &&
                    formikProps.touched.lastName &&
                    formikProps.errors.lastName ?
                    (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
            </Form.Group>

            <Form.Group controlId="formEmail">
                <Form.Label>Email address</Form.Label>
                <Form.Control type="email"
                    placeholder="Enter email"
                    name="email"
                    value={formikProps.values.email}
                    onChange={formikProps.handleChange} />
                <Form.Text className="text-muted">
                    Please enter your email id. Receipt will be sent to the email id entered here.
                </Form.Text>
                {formikProps.touched &&
                    formikProps.touched.email &&
                    formikProps.errors.email ?
                    (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
            </Form.Group>

            <Form.Group controlId="formMobile">
                <Form.Label>Mobile number</Form.Label>
                <Form.Control type="text"
                    placeholder="04XXXXXXXX(AU)/02XXXXXXXXX(NZ)"
                    pattern="0[0-9]{9,10}"
                    name="mobileNumber"
                    onChange={formikProps.handleChange} />
                <Form.Text className="text-muted">
                    Please enter your mobile number starting with 04 (AU) / 02 (NZ) .
                </Form.Text>
                {formikProps.touched &&
                    formikProps.touched.mobileNumber &&
                    formikProps.errors.mobileNumber ?
                    (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
            </Form.Group>

            {props.uiState.center === null ? <Form.Group controlId="formMobile">
                <Form.Label>Select Centre</Form.Label>
                <Form.Control as="select"
                    name="centre"
                    onChange={formikProps.handleChange} >
                    <option></option>
                    {renderCentreSelect()}
                </Form.Control>
                <Form.Text className="text-muted">
                    Please select the centre.
                </Form.Text>
                {formikProps.touched &&
                    formikProps.touched.centre &&
                    formikProps.errors.centre ?
                    (<div className="fade alert alert-danger show">{formikProps.errors.centre}</div>) : (<React.Fragment />)}
            </Form.Group> : <React.Fragment />}

            {eventNeedsDonorAddress === true ? <Form.Group as={Row} controlId="frmGrpAddress">
                <Col sm={12} md={6}>
                    <Form.Label>Street Address</Form.Label>
                    <Form.Control type="text" name="address" value={formikProps.values.address} onChange={formikProps.handleChange} />
                    {formikProps.touched &&
                     formikProps.touched.address &&
                     formikProps.errors.address ?
                        (<div className="fade alert alert-danger show">{formikProps.errors.address}</div>) : (<React.Fragment />)}
                </Col>
                <Col sm={12} md={3}>
                    <Form.Label>Suburb</Form.Label>
                    <Form.Control type="text" name="suburb" value={formikProps.values.suburb} onChange={formikProps.handleChange} />
                    {formikProps.touched &&
                    formikProps.touched.suburb &&
                    formikProps.errors.suburb ?
                        (<div className="fade alert alert-danger show">{formikProps.errors.suburb}</div>) : (<React.Fragment />)}
                </Col>
                <Col sm={12} md={3}>
                    <Form.Label>Zip</Form.Label>
                    <Form.Control type="text" name="zip" value={formikProps.values.zip} onChange={formikProps.handleChange} />
                    {formikProps.touched &&
                    formikProps.touched.zip &&
                    formikProps.errors.zip ?
                        (<div className="fade alert alert-danger show">{formikProps.errors.zip}</div>) : (<React.Fragment />)}
                </Col>
            </Form.Group> : <React.Fragment />}

            <Button variant="primary" type="submit" size="lg">
                NEXT
            </Button>
        </Form>);
    }

    const renderDonorDetailsForm = () => {
        return (<Formik
            enableReinitialize={true}
            initialValues={defaultFormValues}
            onSubmit={onNextClick}
            render={renderForm}
            validationSchema={donorDetailsSchema}
        />);
    }

    const redirectToDonationDetails = () => {
        return (<Redirect to="/donation-details" />)
    }

    return (
        <div>
            {donorDetailsEntered ? redirectToDonationDetails() : renderDonorDetailsForm()}
        </div>
    );
}

const mapStateToProps = (state: any) => {
    return {
        donor: state.donor,
        uiState: state.uiState
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateDonorDetails: (donorDetails: DonorDetails) => dispatch(updateDonorDetails(donorDetails)),
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
        setCenter: (center: number) => dispatch(setCenter(center)),
    }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AcquireDonorDetails));
