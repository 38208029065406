import  React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

export default function CanberraMandirMurtiPratishthaInstalmentRenderer() {

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                Canberra Mandir Murti Pratistha Seva (Instalments)
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="justify-content-md-center" style={{ color: "#000000", marginBottom: "15px", fontSize: "20px" }}>
                                <span> If you want to Donate $5,000 or over and wish to pay it in instalments, <br />
                                    please contact on
                                    <span style={{ fontWeight: "bold", fontStyle: "italic" }}> +61  431 121 816 </span>
                                    or email at <span style={{
                                        fontWeight: "bold",
                                        fontStyle: "italic"
                                    }}>can.accounts@au.baps.org</span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}