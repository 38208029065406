import Axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "../../AppSettings";
import EventGroup from "../../models/EventGroup";

export const LOAD_EVENTGROUPS = "LOAD_EVENTGROUPS";
export const LOAD_EVENTGROUPS_START = "LOAD_EVENTGROUPS_START";
export const LOAD_EVENTGROUPS_SUCCESS = "LOAD_EVENTGROUPS_SUCCESS";

export interface LoadEventGroupsStart {
    type: typeof LOAD_EVENTGROUPS_START;
}

export interface LoadEventGroupsSuccess {
    type: typeof LOAD_EVENTGROUPS_SUCCESS;
    eventGroups: Array<EventGroup>;
}

export type EventGroupsActions = LoadEventGroupsStart | LoadEventGroupsSuccess;

const createLoadEventGroupsAction = (): LoadEventGroupsStart => {
    return {
        type: LOAD_EVENTGROUPS_START
    };
}

const createLoadEventGroupsSuccess = (eventGroups: Array<EventGroup>): LoadEventGroupsSuccess => {
    return {
        type: LOAD_EVENTGROUPS_SUCCESS,
        eventGroups
    };
}

export const loadEventGroups = (token: string) => async (dispatch: any) => {
    dispatch(createLoadEventGroupsAction());

    const requestConfig: AxiosRequestConfig = {};
    requestConfig.headers = { 'Authorization': `Bearer ${token}` };

    const response = await Axios.get<Array<EventGroup>>(`${apiBaseUrl}/api/admin/eventGroups`, requestConfig);
    dispatch(createLoadEventGroupsSuccess(response.data));
}