import Event from "../../models/Event";
import CustomFieldValue from "../../models/CustomFieldValue";

export const UPDATE_SEVA_COUNT_FOR_EVENT = "UPDATE_SEVA_COUNT_FOR_EVENT";

export const updateSevaDetailsForEvent = (
    sevaCount: number,
    customFieldValues: CustomFieldValue[], 
    event: Event,
    sevaAmount?: number) => {
    return {
        type: UPDATE_SEVA_COUNT_FOR_EVENT,
        data: {
            event,
            sevaCount,
            customFieldValues,
            sevaAmount
        }
    };
}