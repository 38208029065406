import Axios from "axios";
import React, { ReactNode, useEffect, useState } from "react";
import { Card, Container, Row, Button, Col, Form } from "react-bootstrap";
import { apiBaseUrl } from "../../AppSettings";
import DonorDetails from "../../store/actions/DonorDetails";
import Centre from "../../models/Centre";

export interface InstalmentRendererProps {
    donor: DonorDetails,
    eventGroup: string,
    eventName: string,
    children: ReactNode
}

export default function InstalmentRenderer(props: InstalmentRendererProps) {

    const [savingDetails, setSavingDetails] = useState<boolean>(false);
    const [detailsSaved, setDetailsSaved] = useState<boolean>(false);

    useEffect(() => {
        console.log(props);
    }, []);

    const saveDonorDetails = async () => {
        setSavingDetails(true);

        const request = {
            email: props.donor.email,
            firstName: props.donor.firstName,
            lastName: props.donor.lastName,
            mobile: props.donor.mobile,
            center: parseInt(props.donor.centre.toString()),
            event: props.eventGroup
        };

        const response = await Axios.post(`${apiBaseUrl}/api/SquareMeterInstallmentRegistration`, request);
        alert("Your details are saved");
        setDetailsSaved(true);
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.eventName}
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={12}>
                            {props.children}
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12}>
                            <Form.Group>
                                <Button hidden={detailsSaved} disabled={savingDetails} variant="primary" size="lg" onClick={saveDonorDetails}>
                                    Save my Details
                                </Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}

