
export const UPDATE_UI_STATE = "UPDATE_UI_STATE";
export const SET_CENTER = "SET_CENTER";

export const setEventGroupName = (eventGroupName: string) => {

    const formattedEventGroupName = !eventGroupName ? 
    "" : 
    eventGroupName.trim().toLowerCase();

    return {
        type: UPDATE_UI_STATE,
        data: formattedEventGroupName
    }
}

export const setCenter = (center: number) => {
    return {
        type: SET_CENTER,
        data: center
    }
}