import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import Axios from "axios";

import DonationForEvent from "./store/actions/DonationForEvent";
import DonorDetails from "./store/actions/DonorDetails";
import { Alert, Spinner } from "react-bootstrap";
import CreateOrderRequest from "./CreateOrderIdRequest";
import { apiBaseUrl, checkoutBaseUrl } from "./AppSettings";

interface Props {
    donorDetails: DonorDetails,
    donationItems: Array<DonationForEvent>,
    eventGroupName: string
}

interface OrderUrlResponse {
    url: string
}

export default function SquarePaymentButton(props: Props) {

    const [paymentUrl, setPaymentUrl] = React.useState<string | null>();
    const [paymentUrlError, setPaymentUrlError] = React.useState<string | null>();
    const [generatingPaymentUrl, setGeneratingPaymentUrl] = React.useState<boolean>(true);

    useEffect(() => {
        getPaymentUrl();
    }, []);

    const getPaymentUrl = async ()  => {
        const request: CreateOrderRequest = {
            donorEmail: props.donorDetails.email,
            donorFirstName: props.donorDetails.firstName,
            donorLastName: props.donorDetails.lastName,
            donorMobile: props.donorDetails.mobile,
            donationItems: props.donationItems,
            center: parseInt(props.donorDetails.centre.toString()),
            address: props.donorDetails.address,
            suburb: props.donorDetails.suburb,
            zip: props.donorDetails.zip ? parseInt(props.donorDetails.zip.toString()) : undefined
        };

        setGeneratingPaymentUrl(true);

        try {
            const response = await Axios.post<OrderUrlResponse>(`${apiBaseUrl}/api/SquareOrders/create-order`, request);
            
            setGeneratingPaymentUrl(false);

            var checkoutUrl = "";
            if(response.data.url.startsWith("https"))
                checkoutUrl = response.data.url;
            else
                checkoutUrl = checkoutBaseUrl + response.data.url;

            setPaymentUrl(checkoutUrl);
        }
        catch (ex) {
            setGeneratingPaymentUrl(false);
            setPaymentUrlError(ex);
        }
    }

    const renderLoading = () => {
        return (<div>
            <Alert key="generatingPaymentUrl" variant="primary">
                <h3>Please wait while we initialize the payment process. <Spinner animation="grow" /></h3>
            </Alert>
        </div>)
    }

    const renderPayButton = () => {
        return (<div>
            <a className="btn btn-primary btn-block btn-lg"
                href={paymentUrl as string}>
                Pay using Square
            </a>
        </div>)
    }

    const render = () => {
        if (generatingPaymentUrl)
            return renderLoading();

        if (paymentUrl)
            return renderPayButton();

        return (<React.Fragment />);
    }

    return (<div>
        {render()}
    </div>);
}