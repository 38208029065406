import React, { useState } from "react";
import { Col, Form, Row } from "react-bootstrap";
import EventGroup from "../models/EventGroup";

interface EventGroupSelectorProps {
    onSelectedEventsChanged: (selectedEventIds: number[]) => void;
    eventGroups: Array<EventGroup>;
}

export default function EventSelector(props: EventGroupSelectorProps) {

    const [selectedEventGroup, setSelectedEventGroup] = useState<string>("");
    const NullGroupName: string = "Donations";

    const onSelectedEventGroupChanged = (event: any) => {
        const groupNameToMatch = event.target.value === NullGroupName ? null : event.target.value;
        setSelectedEventGroup(event.target.value);
        
        const selectedGroup = props.eventGroups.find(eg => eg.name === groupNameToMatch);
        const normalizedSelectedGroup = selectedGroup as EventGroup;
        props.onSelectedEventsChanged(normalizedSelectedGroup.events.map(e => e.id));
    }

    const getEventGroupDisplayName = (eventGroup: EventGroup) => {
        return eventGroup.name ?? NullGroupName;
    }

    return (
        <Form>
            <Form.Group as={Row}>
                <Form.Label column sm="3">Select the Event</Form.Label>
                <Col sm="9">
                    <Form.Control as="select" 
                        value={selectedEventGroup} 
                        onChange={onSelectedEventGroupChanged}>
                        <option value={-1}>-</option>
                        {props.eventGroups.map(e => {
                            return (<option>{getEventGroupDisplayName(e)}</option>)
                        })}
                    </Form.Control>
                </Col>
            </Form.Group>
        </Form>
    )
}