import React, { useEffect } from "react";
import Axios from "axios";
import { apiBaseUrl } from "./AppSettings";
import DonationEventRenderer from "./DonationEventRenderer";
import Event from "./models/Event";
import { updateSevaDetailsForEvent } from "./store/actions/DonationDetailsActions";
import { connect } from "react-redux";
import { Alert } from "react-bootstrap";
import { Link } from "react-router-dom";
import CustomFieldValue from "./models/CustomFieldValue";
import JivanCharitraEventRenderer from "./eventRenderers/JivanCharitraEventRenderer";
import AksharDiaryEventRenderer from "./eventRenderers/AksharDiaryEventRenderer";
import MahotsavSabhaRenderer from "./eventRenderers/MahotsavSabhaRenderer";
import GharMandirEventRenderer from "./eventRenderers/GharMandirEventRenderer";
import AllowAnyAmountEventRenderer from "./eventRenderers/AllowAnyAmountEventRenderer";
import SquareMeterInstalmentRenderer from "./eventRenderers/instalmentRenderers/SquareMeterInstalmentRenderer";
import DonorDetails from "./store/actions/DonorDetails";
import KiteFestivalEventRenderer from "./eventRenderers/KiteFestivalAttendeEventRenderer";
import KiteFestivalCarRegoEventRenderer from "./eventRenderers/KiteFestivalCarRegoEventRenderer";
import CanberraMandirInstalmentRenderer from "./eventRenderers/CanberraMandirInstalmentRenderer";
import MelbourneMandirInstalmentRenderer from "./eventRenderers/MelbourneMandirInstalmentRenderer";
import AllowAnySevaCountEventRenderer from "./eventRenderers/AllowAnySevaCountEventRenderer";
import CanberraMandirMurtiPratishthaInstalmentRenderer from "./eventRenderers/instalmentRenderers/CanberraMandirMurtiPratishthaInstalmentRenderer";
import AksharPurushottamMaharajMurtiSeva from "./eventRenderers/instalmentRenderers/AksharPurushottamMaharajMurtiSeva";
import VicharanSevaEventRenderer from "./eventRenderers/VicharanSevaEventRenderer";

interface AcquireDonationDetailsProps {
    totalDonationAmount: number,
    eventGroupName: string,
    donor: DonorDetails,
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
}

function AcquireDonationDetails(props: AcquireDonationDetailsProps) {

    const [events, setEvents] = React.useState<Event[]>([]);

    useEffect(() => {
        loadEvents(props.eventGroupName);
    }, []);

    const loadEvents = async (eventGroupName: string) => {
        const response = await Axios.get(`${apiBaseUrl}/api/events?eventGroupName=${eventGroupName}`);
        const events = response.data;
        setEvents(events);
    }

    const onUpdateSevaDetailsForEvent = (
        sevaCount: number,
        customFieldValues: CustomFieldValue[],
        event: Event,
        sevaAmount?: number) => {
        props.updateSevaDetailsForEvent(sevaCount, customFieldValues, event, sevaAmount);
    }

    const renderEvents = () => {
        const eventRenderers: Array<JSX.Element> = [];
        let lastDescription: string = "";

        for (var index = 0; index < events.length; index++) {

            if(events[index].eventGroupName === "sydney-ap-maharaj-abhishek-yajman-seva")
                continue;

            if (events[index].eventGroupName === "jivan-charitra") {
                eventRenderers.push((<JivanCharitraEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />))
            }

            else if (events[index].eventGroupName === "jivan-charitra-part-4") {
                eventRenderers.push((<JivanCharitraEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />))
            }

            else if (events[index].eventGroupName === "akshar-diary") {
                eventRenderers.push((<AksharDiaryEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />))
            }

            else if(events[index].name === "kite-festival-Attendees") {
                eventRenderers.push((<KiteFestivalEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />))
            }

            else if(events[index].name === "Kite-festival-Parking") {
                eventRenderers.push((<KiteFestivalCarRegoEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />));
            }

            else if (events[index].eventGroupName?.indexOf("vicharan-2025-seva") === 0) {
                eventRenderers.push(<VicharanSevaEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                    sevaCountMultiplier={1} />);
            }

            else if(events[index].allowAnyAmount) {
                eventRenderers.push((<AllowAnyAmountEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />))
            }

            else if (events[index].eventGroupName === "ghar-mandir") {
                eventRenderers.push((<GharMandirEventRenderer
                    description={events[index].description}
                    event={events[index]}
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    key={`ghar-mandir-${index}`}
                />))
            }

            else if (events[index].eventGroupName?.indexOf("jholi-utsav") === 0 &&
                events[index].description !== "dynamic") {

                eventRenderers.push((<MahotsavSabhaRenderer
                    description={events[index].description}
                    renderDescription={lastDescription !== events[index].description}
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />));

                lastDescription = events[index].description;
            }

            else if (events[index].eventGroupName?.indexOf("canberra-mandir-murti-pratistha") === 0 &&
                events[index].description !== "dynamic") {

                eventRenderers.push((<MahotsavSabhaRenderer
                    description={events[index].description}
                    renderDescription={lastDescription !== events[index].description}
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />));

                lastDescription = events[index].description;
            }

            else if (events[index].eventGroupName?.indexOf("nilkanth-varni-seva") === 0 &&
                events[index].description !== "dynamic") {

                eventRenderers.push((<MahotsavSabhaRenderer
                    description={events[index].description}
                    renderDescription={lastDescription !== events[index].description}
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                />));

                lastDescription = events[index].description;
            }

            else if(events[index].eventGroupName?.indexOf("karika-Mahapuja-Sabha-Fee-Pamyent") === 0) {
                eventRenderers.push(<DonationEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]} 
                    amountLabel="Fee per attendee"
                    selectCountLabel="Number of attendees"/>);
            }

            else if(events[index].eventGroupName?.indexOf("melbourne-south-mandir-seva") === 0) {
                eventRenderers.push(<AllowAnySevaCountEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                    amountLabel="Seva amount for 1 Square Metre"
                    selectCountLabel="Number of Square Metres"
                    sevaCountMultiplier={10}/>);
                eventRenderers.push(<MelbourneMandirInstalmentRenderer/>);
            }

            else if(events[index].eventGroupName?.indexOf("dhatu-murti-2024") === 0) {
                eventRenderers.push(<AllowAnySevaCountEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]}
                    amountLabel="Seva amount for 1 Murti"
                    selectCountLabel="Number of Murti"
                    sevaCountMultiplier={1}/>);
            }

            

            else {
                eventRenderers.push(((<DonationEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={events[index]} />)));
            }
        }

        if (isMatchingEventGroup("syd-mandir-stg-1-seva-all", events) ||  
            isMatchingEventGroup("syd-mandir-stg-1-seva-sydney", events)) {
            const eventGroup = events[0].eventGroupName;
            eventRenderers.push((<SquareMeterInstalmentRenderer donor={props.donor} eventGroup={eventGroup} />));
        }

        if(isMatchingEventGroup("canberra-mandir-seva", events)) { 
            eventRenderers.push((<CanberraMandirInstalmentRenderer />))
        }

        if(isMatchingEventGroup("canberra-mandir-murti-pratistha", events)) {
            eventRenderers.push((<CanberraMandirMurtiPratishthaInstalmentRenderer />));
        }

        if(isMatchingEventGroup("sydney-ap-maharaj-abhishek-yajman-seva", events)) {
            eventRenderers.push(...getSydneyApMaharajAbhishekYajmanSevaRenderers(events));
        }

        return eventRenderers;
    }

    const isMatchingEventGroup = (eventGroupNameToCheck: string, events: Event[]) => {
        if(events && events[0] && (events[0].eventGroupName === eventGroupNameToCheck)) {
                return true;
        }
            return false;
    }

    const getSydneyApMaharajAbhishekYajmanSevaRenderers = (events: Event[]) : JSX.Element[] => {
        let eventRenderers: JSX.Element[] = events.map<JSX.Element>(evt => {
            if(evt.name.indexOf("Mukhya Yajman") !== -1)
                return (<MahotsavSabhaRenderer
                    description={evt.description}
                    renderDescription={true}
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    event={evt}
                />);

            else if(evt.name.indexOf("Yajman Seva") !== -1)
                return ((<AllowAnyAmountEventRenderer
                    updateSevaDetailsForEvent={onUpdateSevaDetailsForEvent}
                    minDonationAmount={500}
                    event={evt}
                />));

            return (<React.Fragment/>)
        });

        eventRenderers.push(<AksharPurushottamMaharajMurtiSeva donor={props.donor} eventGroup={events[0].eventGroupName} />);
        return eventRenderers;
    }

    return (<div>
        <div>
            <Alert key="totalSevaAmount" variant="primary">
                <h5>Total Amount - ${props.totalDonationAmount}</h5>
            </Alert>
        </div>
        <div>
            {renderEvents()}
        </div>
        <div style={{ marginTop: "15px" }}>
            {(props.totalDonationAmount > 0) ? (<Link
                to="/confirm"
                className="btn btn-primary btn-lg">
                NEXT
            </Link>) : (<React.Fragment />)}
        </div>
    </div>);
}

const mapStateToProps = (state: any) => {
    return {
        totalDonationAmount: state.donations.totalSevaAmount,
        eventGroupName: state.uiState.eventGroupName,
        donor: state.donor
    };
}

const mapDispatchToProps = (dispatch: any) => {
    return {
        updateSevaDetailsForEvent: (
            sevaCount: number,
            customFieldValues: CustomFieldValue[],
            event: Event,
            sevaAmount?: number) =>
            dispatch(updateSevaDetailsForEvent(sevaCount, customFieldValues, event, sevaAmount))
    };
}

export default connect(mapStateToProps, mapDispatchToProps)(AcquireDonationDetails);