import React, {ReactElement, useEffect, useRef, useState} from "react";
import QrScanner from "qr-scanner";
import {Alert, Badge, Button, Col, Container, Fade, Form, Row, Spinner} from "react-bootstrap";
import Axios, {AxiosResponse} from "axios";
import {apiBaseUrl} from "./AppSettings";
import {connect} from "react-redux";
import {setEventGroupName} from "./store/actions/UIStateActions";

enum KidsDiwaliCheckinState {
    SelectCheckinMethod,
    CheckinUsingQR,
    CheckinUsingMobileNumber,
    CheckingIn,
    Error,
    Success
}

interface DispatchProps {
    updateEventGroupName: (eventGroupName: string) => void;
}

interface KidsDiwaliQrCodeData {
    Email: string;
    FirstName: string;
    LastName: string;
    MobileNumber: string;
    RegistrationId: string;
    Slot: string;
}

enum CheckInMode {
    Mobile,
    RegistrationId
}

interface KidsDiwaliRegistrationCheckInRequest {
    Mode: CheckInMode;
    Value: string;
}

interface CheckInResponse {
    kids: number;
    adults: number;
    success: boolean;
    error: string;
}
type ComponentProps = DispatchProps;

export function KidsDiwaliCheckin(props: ComponentProps) {
    const videoRef = useRef<HTMLVideoElement>(null);
    const qrScanner = useRef<QrScanner | undefined>(undefined);
    const [checkinState, setCheckinState] = useState<KidsDiwaliCheckinState>(KidsDiwaliCheckinState.SelectCheckinMethod);
    const [checkInError, setCheckInError] = useState<string>("");
    const [hideSuccess, setHideSuccess] = useState<boolean>(true);
    const [mobileNumber, setMobileNumber] = useState<string|undefined>(undefined);
    const [checkInResponse, setCheckInResponse] = useState<CheckInResponse|undefined>(undefined);


    useEffect(() => {
        props.updateEventGroupName("kids-diwali-checkin");
        if (!videoRef.current) return;
        qrScanner.current = new QrScanner(
            videoRef.current!,
            result => onQRCodeScanResult(result),
            {
                highlightScanRegion: true
            }
        );
    }, [videoRef]);

    const processCheckin = async (request: KidsDiwaliRegistrationCheckInRequest): Promise<void> => {
        const url = `${apiBaseUrl}/api/KidsDiwaliRegistration/mark-attended`;
        try {
            const response = await Axios.post<CheckInResponse>(url, request, {
                headers: {
                    "x-api-key": "F8E57CAE-3BEC-4040-BBE0-C6A0F526D342"
                }
            });

            if(response.data.success) {
                setCheckinState(KidsDiwaliCheckinState.Success);
                setCheckInResponse(response.data);
            } else {
                setCheckinState(KidsDiwaliCheckinState.Error);
                setCheckInError(response.data.error);
            }
        } catch (error) {
            setCheckinState(KidsDiwaliCheckinState.Error);

            if (!error.response) {
                setCheckInError(error.message);
                return;
            }

            const parsedResponse: AxiosResponse = error.response;
            setCheckInError(`${parsedResponse.status} - ${parsedResponse.statusText}`);
        }
    }

    const onQRCodeScanResult = (result: QrScanner.ScanResult) => {
        const parsedResult: KidsDiwaliQrCodeData = JSON.parse(result.data);
        console.log(parsedResult);
        qrScanner.current?.stop();
        setCheckinState(KidsDiwaliCheckinState.CheckingIn);
        processCheckin({
            Mode: CheckInMode.RegistrationId,
            Value: parsedResult.RegistrationId
        });
    }

    const startResetTimer = () => {
        setTimeout(() => {
            btnResetClicked();
        }, 3000);
    }

    const btnScanQrCodeClicked = () => {
        setCheckinState(KidsDiwaliCheckinState.CheckinUsingQR);
    }

    const btnStartMobileNumberCheckin = () => {
        setCheckinState(KidsDiwaliCheckinState.CheckinUsingMobileNumber);
    }

    const startQRScanner = (): ReactElement => {
        qrScanner.current?.start();
        return (<React.Fragment/>);
    }

    const mobileNumberChanged = (newValue: string) => {
        setMobileNumber(newValue);
    }

    const btnCheckinUsingMobile = () => {
        if(!mobileNumber) {
            alert("Please enter mobile number");
        }
        processCheckin({
            Mode: CheckInMode.Mobile,
            Value: mobileNumber!
        });
    }

    const renderMobileNumber = (): ReactElement => {
        return (
            <Container style={{marginTop: "10px"}}>
                <Row>
                    <Col sm={12} md={6}>
                        <Form.Control size="lg" type="text" placeholder="Enter mobile number" onChange={evt => mobileNumberChanged(evt.target.value)} />
                    </Col>
                    <Col sm={12} md={6}>
                        <Button variant="primary" type="button" onClick={btnCheckinUsingMobile}>
                            Checkin Using Mobile Number
                        </Button>
                    </Col>
                </Row>
            </Container>
        );
    }

    const btnResetClicked = () => {
        qrScanner.current?.stop();
        setCheckinState(KidsDiwaliCheckinState.SelectCheckinMethod);
    }

    const renderSelectCheckinMethod = (): JSX.Element => {
        return (<Container style={{marginTop: "10px"}}>
            <Row>
                <Col sm={12} md={6} style={{marginTop: "10px"}}>
                    <Button variant="primary" size="lg" style={{width: "90%"}} onClick={btnScanQrCodeClicked}>
                        Scan QR Code
                    </Button>
                </Col>
                <Col sm={12} md={6} style={{marginTop: "10px"}}>
                    <Button variant="info" size="lg" style={{width: "90%"}} onClick={btnStartMobileNumberCheckin}>
                        Enter Mobile Number
                    </Button>
                </Col>
            </Row>
        </Container>)
    }

    const renderReset = () => {
        return (<Container>
            <Row>
                <Col sm={12} md={12} style={{marginTop: "10px"}}>
                    <Button variant="warning" size="lg" style={{width: "95%"}} onClick={btnResetClicked}>
                        RESET
                    </Button>
                </Col>
            </Row>
        </Container>)
    }

    const renderCheckingIn = () => {
        return (
            <Container style={{marginTop: "10px"}}>
                <Row>
                    <Col sm={12} md={12}>
                        <Button variant="primary" disabled style={{width: "95%"}}>
                            <Spinner
                                as="span"
                                animation="grow"
                                size="sm"
                                role="status"
                                aria-hidden="true"
                            />
                            &nbsp;&nbsp;Please wait...
                        </Button>
                    </Col>
                </Row>
            </Container>);
    }

    const renderErrorCheckingIn = () => {
        startResetTimer();
        return (
            <Container style={{marginTop: "10px"}}>
                <Row>
                    <Col sm={12} md={12}>
                        <Alert variant="danger">
                            Error Checking In - {checkInError}
                        </Alert>
                    </Col>
                </Row>
            </Container>);
    }

    const renderCheckInSuccess = () => {
        startResetTimer();
        return (
            <Container style={{marginTop: "10px"}}>
                <Row>
                    <Col sm={12} md={12}>
                        <Alert variant="success">
                            Checked In Successfully
                        </Alert>
                    </Col>
                </Row>
                <Row>
                    <Col sm={12} md={6}>
                        <h1>Adults <Badge variant="primary">{checkInResponse?.adults}</Badge></h1>
                    </Col>
                    <Col sm={12} md={6}>
                        <h1>Kids <Badge variant="primary">{checkInResponse?.kids}</Badge></h1>
                    </Col>
                </Row>
            </Container>);
    }

    const render = () => {
        switch (checkinState) {
            case KidsDiwaliCheckinState.SelectCheckinMethod:
                return (renderSelectCheckinMethod());
            case KidsDiwaliCheckinState.CheckinUsingQR:
                return (startQRScanner());
            case KidsDiwaliCheckinState.CheckinUsingMobileNumber:
                return (renderMobileNumber());
            case KidsDiwaliCheckinState.CheckingIn:
                return (renderCheckingIn());
            case KidsDiwaliCheckinState.Error:
                return (renderErrorCheckingIn());
            case KidsDiwaliCheckinState.Success:
                return (renderCheckInSuccess());
        }

        return (<React.Fragment/>);
    }

    return (<div>
        {renderReset()}
        {render()}
        <Row style={{marginTop: "10px"}}>
            <Col sm={12} md={2}></Col>
            <Col sm={12} md={8}>
                <div style={{margin: "auto"}}>
                    <video style={{width: "90%"}} ref={videoRef}></video>
                </div>
            </Col>
            <Col sm={12} md={2}></Col>
        </Row>
    </div>)
}

const mapStateToProps = (state: any): any => {return {}
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(KidsDiwaliCheckin);
