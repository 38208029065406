import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';
import { applyMiddleware, createStore } from 'redux';
import rootReducer from './store/rootReducer';
import { Provider } from 'react-redux';
import { Auth0Provider } from "@auth0/auth0-react";
import { auth0ReportApiAudience } from './AppSettings';
import thunk from 'redux-thunk';

const store = createStore(rootReducer, applyMiddleware(thunk));

ReactDOM.render(
  <React.StrictMode>
    <Auth0Provider
      domain="sampark.au.auth0.com"
      clientId="TJuiiOSh3FpMJwp656VG32uX6hB5UBmY"
      audience={auth0ReportApiAudience}
      redirectUri={`${window.location.origin}/reports`}
    >
      <Provider store={store}>
        <App />
      </Provider>
    </Auth0Provider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
