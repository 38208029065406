import React from "react";
import { Card, Container, Row, Form, Col, Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export default function JivanCharitraEventRenderer(props: DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(0);

    const onSelectedCountChanged = (event: any) => {
        const parsedSevaCount = !event.target.value ? 0 : parseInt(event.target.value);

        if (isNaN(parsedSevaCount)) {
            alert("Enter numeric value for count");
            return;
        }

        setSelectedSevaCount(parsedSevaCount);
        raiseUpdatedEvent(parsedSevaCount);
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event);
    }

    const renderSevaCount = () => {
        const sevaCountOptionElement: JSX.Element[] = [];

        for (let counter = 1; counter <= props.event.maxCount; counter++) {
            sevaCountOptionElement.push((<option key={`SevaCount-${counter}`} value={counter}>{counter}</option>))
        }

        return sevaCountOptionElement;
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderEventImage = () => {
        if (props.event.name.indexOf("Part - 1") !== -1)
            return (<img style={{ width: "50%" }} src="jivan-charitra/part-1.jpg" />);

        if (props.event.name.indexOf("Part - 2") !== -1)
            return (<img style={{ width: "50%" }} src="jivan-charitra/part-2.jpg" />);

        if (props.event.name.indexOf("Part - 3") !== -1)
            return (<img style={{ width: "50%" }} src="jivan-charitra/part-3.jpg" />);

        if (props.event.name.indexOf("Part - 4") !== -1)
            return (<img style={{ width: "50%" }} src="jivan-charitra/part-4.jpg" />);

        return <React.Fragment />
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{props.event.description}</Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text"
                                    value={`$${props.event.sevaAmount}`}
                                    readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Select the count</Form.Label>
                                <Form.Control as="select"
                                    value={selectedSevaCount}
                                    onChange={onSelectedCountChanged}>
                                    <option value={-1}>Select count</option>
                                    {renderSevaCount()}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={{ span: 6, offset: 3 }} sm={12}>
                            {renderEventImage()}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}