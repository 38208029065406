import React from "react";
import { DonationEventRendererProps } from "../DonationEventRenderer";
import { Badge, Button, Card, Col, Container, Form, Row } from "react-bootstrap";

enum ControlMode {
    setAmount,
    otherAmount
}

export default function VicharanSevaEventRenderer(props: DonationEventRendererProps) {
    const [sevaAmount, setSevaAmount] = React.useState<number | null>();
    const [mode, setMode] = React.useState<ControlMode>(ControlMode.setAmount);

    const onSevaAmountChanged = (event: any) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value))
            return;

        if(value === -2) {
            setMode(ControlMode.otherAmount);
        }

        const calculatedSevaAmount = value <= 0 ? 0 : value;
        setSevaAmount(calculatedSevaAmount);
        if (!props.minDonationAmount || value >= props.minDonationAmount) {
            raiseUpdatedEvent(1, calculatedSevaAmount);
        }
        else {
            raiseUpdatedEvent(1, 0);
        }
    }

    const clearDonationAmount = () => {
        setMode(ControlMode.setAmount);
        setSevaAmount(0);
        raiseUpdatedEvent(0, 0);
    }

    const raiseUpdatedEvent = (sevaCount: number, sevaAmount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event,
            sevaAmount);
    }

    const renderTotalSevaAmount = () => {
        return (<h5>
            <Badge variant="primary">${sevaAmount ?? 0}</Badge>
        </h5>)
    }

    const renderAmountError = () => {
        if (sevaAmount && props.minDonationAmount && sevaAmount < props.minDonationAmount)
            return (<div className="fade alert alert-danger show">{`Minimum seva amount is ${props.minDonationAmount}`}</div>);

        return (<React.Fragment />);
    }

    const renderDonationAmountControl = () => {
        if (mode === ControlMode.setAmount) {
            const amounts: [] = JSON.parse(props.event.description);
            return (<Form.Control as="select"
                value={sevaAmount?.toString()}
                onChange={onSevaAmountChanged}>
                <option value={-1}>Select Seva Amount</option>
                {amounts.map(a => (<option value={a}>{a}</option>))}
                <option value={-2}>Other Amount</option>
            </Form.Control>);
        }
        else {
            return (<Form.Control type="text"
                onChange={onSevaAmountChanged}
                value={sevaAmount ?? ""} />);
        }
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Enter Donation Amount</Form.Label>
                                {renderDonationAmountControl()}
                            </Form.Group>
                            {renderAmountError()}
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <Button variant="primary"
                                    type="button"
                                    style={{ display: "block" }}
                                    onClick={clearDonationAmount}>Clear Amount</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>);
}