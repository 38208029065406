import React from "react";
import { Card, Container, Row, Form, Col, Badge, Button, CloseButton } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export default function AllowAnyAmountEventRenderer(props: DonationEventRendererProps) {

    const [sevaAmount, setSevaAmount] = React.useState<number|null>();

    const onSevaAmountChanged = (event: any) => {
        const value = parseFloat(event.target.value);
        if (isNaN(value))
            return;

        setSevaAmount(value);
        if (!props.minDonationAmount || value >= props.minDonationAmount) {
            raiseUpdatedEvent(1, value);
        }
        else {
            raiseUpdatedEvent(1, 0);
        }
    }

    const clearDonationAmount = () => {
        setSevaAmount(0);
        raiseUpdatedEvent(0, 0);
    }

    const raiseUpdatedEvent = (sevaCount: number, sevaAmount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event,
            sevaAmount);
    }

    const renderTotalSevaAmount = () => {
        return (<h5>
            <Badge variant="primary">${sevaAmount ?? 0}</Badge>
        </h5>)
    }

    const renderAmountError = () => {
        if(sevaAmount && props.minDonationAmount && sevaAmount < props.minDonationAmount)
         return (<div className="fade alert alert-danger show">{`Minimum seva amount is ${props.minDonationAmount}`}</div>);

        return (<React.Fragment />);
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{props.event.description}</Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Enter Donation Amount</Form.Label>
                                <Form.Control type="text"
                                    onChange={onSevaAmountChanged}
                                    value={sevaAmount ?? ""} />
                            </Form.Group>
                            {renderAmountError()}
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>&nbsp;</Form.Label>
                                <Button variant="primary" 
                                    type="button"
                                    style={{display:"block"}}
                                    onClick={clearDonationAmount}>Clear Amount</Button>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}