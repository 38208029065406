import React from "react";
import PaypalButton from "./PaypalButton";
import { Container, Row, Col } from "react-bootstrap";
import { Form } from "react-bootstrap";
import { connect } from "react-redux";
import DonorDetails from "./store/actions/DonorDetails";
import DonationForEvent from "./store/actions/DonationForEvent";
import Centre from "./models/Centre";
import SquarePaymentButton from "./SquarePaymentButton";

interface ConfirmDonationDetailsProps {
    donorDetails: DonorDetails,
    totalSevaAmount: number,
    donationItems: Array<DonationForEvent>,
    eventGroupName: string
}

function ConfirmDonationDetails(props: ConfirmDonationDetailsProps) {
   
    const donorDetailsValid = () => {
        return props.donorDetails &&
            props.donorDetails.firstName &&
            props.donorDetails.lastName &&
            props.donorDetails.email &&
            props.donorDetails.mobile &&
            props.donorDetails.centre &&
            props.donationItems &&
            props.donationItems.length > 0;
    }

    const renderDonationDetails = () => {
        return (<Container>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formFirstName">
                        <Form.Label>First Name</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={props.donorDetails.firstName}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formLastName">
                        <Form.Label>Last Name</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={props.donorDetails.lastName}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Email</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={props.donorDetails.email}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formMobileNumber">
                        <Form.Label>Mobile Number</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={props.donorDetails.mobile}
                        />
                    </Form.Group>
                </Col>
            </Row>
            <Row>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formEmail">
                        <Form.Label>Centre</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={Centre[props.donorDetails.centre]}
                        />
                    </Form.Group>
                </Col>
                <Col md={6} sm={12}>
                    <Form.Group controlId="formDonationAmount">
                        <Form.Label>Total Amount</Form.Label>
                        <Form.Control type="text"
                            readOnly={true}
                            value={`${props.totalSevaAmount}`}
                        />
                    </Form.Group>
                </Col>
            </Row>
        </Container>)
    }

    const renderPaypalButton = () => {
        return (
            <div style={{ width: "100%", margin: "auto" }}>
                <PaypalButton
                    donorDetails={props.donorDetails}
                    donationItems={props.donationItems}
                    eventGroupName={props.eventGroupName} />
            </div>);
    }

    const renderSquareButton = () => {
        return (
            <div style={{ width: "100%", margin: "auto" }}>
                <SquarePaymentButton
                    donorDetails={props.donorDetails}
                    donationItems={props.donationItems}
                    eventGroupName={props.eventGroupName} />
            </div>);
    }

    const renderPaymentForm = () => {
        return (<div>
            <div>
                {renderDonationDetails()}
            </div>
            <div>
                {/*renderPaypalButton()*/}
                {renderSquareButton()}
            </div>
        </div>)
    }

    const renderError = () => {
        return (<span>Invalid donor details</span>);
    }

    return (<div>
        {donorDetailsValid() ? renderPaymentForm() : renderError()}
    </div>)
}

const mapStateToProps = (state: any) => {
    return {
        donorDetails: state.donor,
        totalSevaAmount: state.donations.totalSevaAmount,
        donationItems: state.donations.donations,
        eventGroupName: state.uiState.eventGroupName
    }
}

const mapDispatchToProps = () => ({});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmDonationDetails);