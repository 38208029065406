import React from "react";
import { Card, Container, Row, Button, Col, Form } from "react-bootstrap";

export default function CanberraMandirInstalmentRenderer() {

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                Canberra Mandir Square Meter Seva (Instalments)
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={12}>
                            <div className="justify-content-md-center" style={{ marginBottom: "15px", fontSize: "20px" }}>
                                <span> If you want to
                                    <u> Donate 3 SQ Meters or over </u> and wish to pay it in instalments, <br />
                                    please contact Umang on
                                    <span style={{ fontWeight: "bold", fontStyle: "italic" }}> 0431 121 816 </span>
                                    or email at <span style={{ fontWeight: "bold", fontStyle: "italic" }}>can.account@mandir.org.au</span>
                                </span>
                            </div>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}