import { combineReducers } from "redux";
import { DonorDetailsReducer } from "./reducers/DonorDetailsReducer";
import { DonationDetailsReducer } from "./reducers/DonationDetailsReducer";
import { UiStateReducer } from "./reducers/UIStateReducer";
import { EventReducer, EventReducerState } from "./reducers/EventsReducer";
import { ReportReducer, ReportState } from "./reducers/ReportReducer";
import { EventGroupsReducer, EventGroupsReducerState } from "./reducers/EventGroupsReducer";

export interface RootState {
    events: EventReducerState;
    report: ReportState;
    eventGroups: EventGroupsReducerState;
    donor: any;
    donations: any;
    uiState: any;
}

const rootReducer = combineReducers<RootState>({
    donor: DonorDetailsReducer,
    donations: DonationDetailsReducer,
    uiState: UiStateReducer,
    events: EventReducer,
    report: ReportReducer,
    eventGroups: EventGroupsReducer,
});

export default rootReducer;