import React from "react";
import DonorDetails from "../../store/actions/DonorDetails";
import InstalmentRenderer from "./InstalmentRenderer";

export interface SquareMeterInstalmentRendererProps {
    donor: DonorDetails,
    eventGroup: string,
}

export default function SquareMeterInstalmentRenderer(props: SquareMeterInstalmentRendererProps) {

    return (
        <InstalmentRenderer donor={props.donor} eventGroup={props.eventGroup} eventName="Stage 1 - Square Meter Seva (Instalments)">
            <div className="justify-content-md-center" style={{ marginBottom: "15px", fontSize: "20px" }}>
                <span> If you want to
                    <u> Donate 3 SQ Meters or over </u> and wish to pay it in instalments, <br />
                    please contact on
                    <span style={{ fontWeight: "bold", fontStyle: "italic" }}> 0430 272 660 </span>
                    or email at <span style={{ fontWeight: "bold", fontStyle: "italic" }}>fundraising@au.baps.org</span>
                </span>
            </div>
        </InstalmentRenderer>
    )
}

