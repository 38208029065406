import React from "react";
import { Card, Container, Row, Form, Col, Badge, Button } from "react-bootstrap";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export default function AllowAnySevaCountEventRenderer(props: DonationEventRendererProps) {

    const [sevaCount, setSevaCount] = React.useState<number|null>();

    const onSevaCountChanged = (event: any) => {
        if(!event.target.value)
            setSevaCountToZero();

        const value = parseFloat(event.target.value);
        if(isNaN(value) || value <= 0)
            return;

        setSevaCount(value)
        raiseUpdatedEvent(value, props.event.sevaAmount);
    }

    const setSevaCountToZero = () => {
        setSevaCount(undefined);
        raiseUpdatedEvent(0, props.event.sevaAmount);
    }

    const raiseUpdatedEvent = (sevaCount: number, sevaAmount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event);
    }

    const renderTotalSevaAmount = () => {
        const parsedSevaCount = sevaCount ?? 0;
        const totalSevaAmount = props.event.sevaAmount * parsedSevaCount;

        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{props.event.description}</Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>{props.amountLabel ?? "Amount"}</Form.Label>
                                <Form.Control type="text" style={{fontWeight: "bold", background: "none"}}
                                              value={`$${props.event.sevaAmount}`}
                                              readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>{props.selectCountLabel ?? "Select the count"}</Form.Label>
                                <Form.Control type="text"
                                              value={sevaCount ?? ""}
                                              onChange={onSevaCountChanged}/>
                            </Form.Group>
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}
