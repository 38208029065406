import { UPDATE_DONOR_DETAILS } from "../actions/DonorDetailsActions";
import DonorDetails from "../actions/DonorDetails";

const initialState : DonorDetails = {
    firstName: "",
    lastName: "",
    email: "",
    mobile: "",
    centre: 0
};  

export const DonorDetailsReducer = (state = initialState, action: any) => {
    switch (action.type) {
        case UPDATE_DONOR_DETAILS: {
            return { ...action.data };
        }
        default: {
            return state;
        }
    }
}