import React from "react";
import { Card, Container, Row, Form, Col, Badge } from "react-bootstrap";
import { DonationEventRendererProps } from "../DonationEventRenderer";
import CustomFieldValue from "../models/CustomFieldValue";

export default function KiteFestivalEventRenderer(props: DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(-1);
    const [attendeeNames, setAttendeeNames] = React.useState<string[]>(new Array(props.event.maxCount));

    const onSelectedCountChanged = (event: any) => {
        const parsedSevaCount = parseInt(event.target.value);
        clearAttendeeNamesBeyondSevaCount(parsedSevaCount);
        setSelectedSevaCount(parsedSevaCount);
        raiseUpdatedEvent(parsedSevaCount);
    }

    const clearAttendeeNamesBeyondSevaCount = (sevaCount: number) => {
        for (let index:number = sevaCount; index < props.event.maxCount; index++) {
            delete attendeeNames[index];
        }
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        const customFieldValues: CustomFieldValue[] = [];

        const nonNullAttendees = getNonNullAttendees();

        if (attendeeNames && attendeeNames.length > 0) {
            customFieldValues.push({
                customFieldId: props.event.customFields[0].id,
                customFieldValue: JSON.stringify(nonNullAttendees)
            });
        }

        props.updateSevaDetailsForEvent(
            sevaCount,
            customFieldValues,
            props.event);
    }

    const getNonNullAttendees = () => {
        const nonNullAttendees = new Array<string>();

        for (let index = 0; index < attendeeNames.length; index++) {
            if (attendeeNames[index])
                nonNullAttendees.push(attendeeNames[index]);
        }

        return nonNullAttendees;
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderSevaCount = () => {
        const sevaCountOptionElement: JSX.Element[] = [];

        for (let counter = 1; counter <= props.event.maxCount; counter++) {
            sevaCountOptionElement.push((<option key={`SevaCount-${counter}`} value={counter}>{counter}</option>));
        }

        return sevaCountOptionElement;
    }

    const renderAttendeeNamesControls = (): JSX.Element => {
        if (selectedSevaCount <= 0)
            return (<React.Fragment />);

        return (<Form.Group>
            <Form.Label><i><b>**Enter the name of every attendee including Infants**</b></i></Form.Label>
            <Row>
                {renderAttendeeNames()}
            </Row>
        </Form.Group>);
    }

    const handleAttendeeNameChange = (index: number, newValue: string) => {
        attendeeNames[index] = newValue;
        raiseUpdatedEvent(selectedSevaCount);
    }

    const renderAttendeeNames = () : Array<JSX.Element> => {
        const elements: Array<JSX.Element> = new Array<JSX.Element>();

        for (let counter = 0; counter < selectedSevaCount; counter++) {
            elements.push(
            <Col sm={12} md={4}>
                <Form.Control type="text"
                    style={{marginTop: "5px"}}
                    key={`attendee_name${counter}`} 
                    value={attendeeNames[counter]}
                    onChange={e => handleAttendeeNameChange(counter, e.target.value)} />
            </Col>);
        }

        return elements;
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5" className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text" style={{ fontWeight: "bold", background: "none" }}
                                    value={`$${props.event.sevaAmount}`}
                                    readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Select the count</Form.Label>
                                <Form.Control as="select"
                                    value={selectedSevaCount}
                                    onChange={onSelectedCountChanged}>
                                    <option value={-1}>Select count</option>
                                    {renderSevaCount()}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={12}>
                            {renderAttendeeNamesControls()}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}