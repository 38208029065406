import React, { useState } from "react";
import "ag-grid-community/dist/styles/ag-grid.css";
import "ag-grid-community/dist/styles/ag-theme-balham.css";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { AgGridColumn } from "ag-grid-react/lib/agGridColumn";
import { Button, Card } from "react-bootstrap";
import ReportSummary from "../models/ReportSummary";
import { GridApi, GridOptions } from "ag-grid-community";


interface ReportSummaryRendererProps {
    items: Array<ReportSummary>
}

export default function ReportSummaryRenderer(props: ReportSummaryRendererProps) {

    const [gridApi, setGridApi] = useState<GridApi|null>(null);

    const gridOptions: GridOptions = {
        defaultColDef: {
            resizable: true
        }
    };

    const onExportAsCsv = () => {
        gridApi?.exportDataAsCsv(undefined);
    }

    const onGridReady = (params: any) => {
        setGridApi(params.api);
    }

    return (
        <Card>
            <Card.Body>
                <Card.Title>Summary Report</Card.Title>
                <Card.Text>
                    <div className="ag-theme-balham" style={{ height: 250, width: "100%" }}>
                        <AgGridReact
                            gridOptions={gridOptions}
                            onGridReady={onGridReady}
                            rowData={props.items}>
                            <AgGridColumn field="eventName" headerName="Event" sortable={true} filter={true}></AgGridColumn>
                            <AgGridColumn field="center" headerName="Center" sortable={true} filter={true}></AgGridColumn>
                            <AgGridColumn field="orderCount" headerName="Order Count" sortable={true} filter={true}></AgGridColumn>
                        </AgGridReact>
                    </div>
                </Card.Text>
                <Button onClick={onExportAsCsv}>Export as CSV</Button>
            </Card.Body>
        </Card>)
}