import React from 'react';
import logo from './logo.svg';
import './App.css';
import TopNav from './TopNav';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container, Col, Row } from 'react-bootstrap';
import { BrowserRouter } from 'react-router-dom';
import Routes from './Routes';
import Footer from './Footer';

function App() {
  return (
    <BrowserRouter>
      <Container fluid="md">
        <Row>
          <Col>
            <div className="App">
              <header className="App-header">
                <TopNav />
              </header>
            </div>
          </Col>
        </Row>
        <Row>
          <Col>
            <div>
              <Routes />
            </div>
          </Col>
        </Row>
        <Row>
          <Footer />
        </Row>
      </Container>
    </BrowserRouter>
  );
}

export default App;
