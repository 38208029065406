import { SET_CENTER, UPDATE_UI_STATE } from "../actions/UIStateActions";

interface uiState {
    eventGroupName: string;
    center: number | null;
};

const initialState: uiState = {
    eventGroupName: "",
    center: null
};

export const UiStateReducer = (state = initialState, action: any) => {

    switch (action.type) {

        case UPDATE_UI_STATE: {
            const copyOfState = { ...state };
            copyOfState.eventGroupName = action.data;
            return copyOfState;
        }
        case SET_CENTER: {
            const copyOfState = {...state};
            copyOfState.center = action.data;
            return copyOfState;
        }
        default: {
            return state;
        }
    }
}