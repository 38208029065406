import Axios from "axios";
import { Formik, FormikProps } from "formik";
import React, { useEffect } from "react";
import { Button, Form, Row, Col } from "react-bootstrap";
import { connect } from "react-redux";
import { Link, Redirect, withRouter } from "react-router-dom";
import * as Yup from "yup";
import { apiBaseUrl } from "./AppSettings";
import Centre from "./models/Centre";
import CustomField from "./models/CustomField";
import CustomFieldValue from "./models/CustomFieldValue";
import Event from "./models/Event";
import { updateSevaDetailsForEvent } from "./store/actions/DonationDetailsActions";
import DonorDetails from "./store/actions/DonorDetails";
import { updateDonorDetails } from "./store/actions/DonorDetailsActions";
import { setEventGroupName } from "./store/actions/UIStateActions";

interface NilkanthVarniMurtiPratisthaFormProps {
    firstName: string;
    lastName: string;
    center: number;
    email: string;
    mobileNumber: string;
    suburb: string;
    referenceKaryakar: string;
    selectedEvents: []
}

const initialValues: NilkanthVarniMurtiPratisthaFormProps = {
    firstName: "",
    lastName: "",
    center: 0,
    email: "",
    mobileNumber: "",
    suburb: "",
    referenceKaryakar: "",
    selectedEvents: []
}

interface StateProps {
    donor: any;
    uiState: any;
}

interface DispatchProps {
    updateDonorDetails: (donorDetails: DonorDetails) => void;
    updateEventGroupName: (eventGroupName: string) => void;
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event) => void;
}

type ComponentProps = StateProps & DispatchProps;

export function NilkanthVarniMurtiPratistha(props: ComponentProps) {

    const [events, setEvents] = React.useState<Array<Event>>([]);
    const [allDetailsEntered, setAllDetailsEntered] = React.useState<boolean>(false);

    const formSchema = Yup.object().shape({
        firstName: Yup.string().required().max(50),
        lastName: Yup.string().required().max(50),
        mobileNumber: Yup.string().matches(/04([0-9]){8}\b/gm, "Please enter mobile number in 04xxxxxxxx").required(),
        email: Yup.string().email().required(),
        suburb: Yup.string().required().max(255),
        selectedEvents: Yup.array().min(1, "Please select one seva"),
        referenceKaryakar: Yup.string().max(100)
    });

    useEffect(() => {
        props.updateEventGroupName("nilkant-varni-murti-prathishtah");
        loadEvents();
    }, []);

    const loadEvents = async () => {
        const response = await Axios.get<Array<Event>>(`${apiBaseUrl}/api/events?eventGroupName=nilkant-varni-murti-prathishtah`);
        const events = response.data;
        setEvents(events);
    }

    const onSubmit = (values: NilkanthVarniMurtiPratisthaFormProps) => {
        props.updateDonorDetails({
            firstName: values.firstName,
            lastName: values.lastName,
            centre: Centre.Brisbane,
            email: values.email,
            mobile: values.mobileNumber,
        });

        values.selectedEvents.forEach((r, index) => {
            const selectedEvent = events.find(evt => evt.id === parseInt(r)) as Event;

            const mappedCustomFields = mapCustomFields(selectedEvent, values);

            props.updateSevaDetailsForEvent(1, mappedCustomFields, selectedEvent);
        });

        setAllDetailsEntered(true);
    }

    const mapCustomFields = (
        selectedEvent: Event,
        values: NilkanthVarniMurtiPratisthaFormProps) => {

        const mappedCustomFields = new Array<CustomFieldValue>();

        const refKaryakarCustomField = GetCustomField("Ref Karyakar", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: refKaryakarCustomField.id,
                customFieldValue: values.referenceKaryakar
            });

        const suburbCustomField = GetCustomField("Suburb", selectedEvent);
        mappedCustomFields.push(
            {
                customFieldId: suburbCustomField.id,
                customFieldValue: values.suburb
            });

        return mappedCustomFields;
    }

    const GetCustomField = (name: string, event: Event) => {
        return event
            .customFields
            .find(cf => cf.label === name) as CustomField;
    }

    const renderEvents = (formikProps: FormikProps<NilkanthVarniMurtiPratisthaFormProps>): Array<JSX.Element> => {
        const eventsSelectionControls = events.map(evt => (
            <Row>
                <Col md="12" sm="12">
                    <Form.Check
                        type="checkbox"
                        id={`selectevent-${evt.id}`}
                        name="selectedEvents"
                        inline
                        onChange={formikProps.handleChange}
                        value={evt.id}
                        label={'\u00A0' + '\u00A0' + `${evt.name}`}
                    />
                </Col>
            </Row>));

        return eventsSelectionControls;
    }

    const renderForm = () => (<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={formSchema}
    >
        {formikProps => (
            <Form onSubmit={formikProps.handleSubmit}>
                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formFirstName">
                            <Form.Label>First Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter first name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.firstName}
                                name="firstName" />
                            <Form.Text className="text-muted">
                                Please enter your first name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.firstName &&
                                formikProps.errors.firstName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.firstName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formLastName">
                            <Form.Label>Last Name</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter last name"
                                onChange={formikProps.handleChange}
                                value={formikProps.values.lastName}
                                name="lastName" />
                            <Form.Text className="text-muted">
                                Please enter your last name.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.lastName &&
                                formikProps.errors.lastName ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.lastName}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email"
                                placeholder="Enter email"
                                name="email"
                                value={formikProps.values.email}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your email id
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.email &&
                                formikProps.errors.email ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.email}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formMobile">
                            <Form.Label>Mobile number</Form.Label>
                            <Form.Control type="text"
                                placeholder="04XXXXXXXX"
                                pattern="0[0-9]{9}"
                                name="mobileNumber"
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your mobile number starting with 04.
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.mobileNumber &&
                                formikProps.errors.mobileNumber ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.mobileNumber}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formSubub">
                            <Form.Label>Suburb</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter Suburb"
                                name="suburb"
                                value={formikProps.values.suburb}
                                onChange={formikProps.handleChange} />
                            <Form.Text className="text-muted">
                                Please enter your suburb
                            </Form.Text>
                            {formikProps.touched &&
                                formikProps.touched.suburb &&
                                formikProps.errors.suburb ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.suburb}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                    <Col md={6} sm={12}>
                        <Form.Group controlId="formRefKaryakar">
                            <Form.Label>Reference Karyakar</Form.Label>
                            <Form.Control type="text"
                                placeholder="Enter the name of Karyakar"
                                name="referenceKaryakar"
                                value={formikProps.values.referenceKaryakar}
                                onChange={formikProps.handleChange} />
                            {formikProps.touched &&
                                formikProps.touched.referenceKaryakar &&
                                formikProps.errors.referenceKaryakar ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.referenceKaryakar}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>

                <Row>
                    <Col md={12} sm={12}>
                        <Form.Group>
                            <Form.Label>Select the Seva</Form.Label>
                            {events ? renderEvents(formikProps) : <React.Fragment />}
                            {formikProps.touched &&
                                formikProps.touched.selectedEvents &&
                                formikProps.errors.selectedEvents ?
                                (<div className="fade alert alert-danger show">{formikProps.errors.selectedEvents}</div>) : (<React.Fragment />)}
                        </Form.Group>
                    </Col>
                </Row>


                <Button variant="primary" type="submit" size="lg">
                    NEXT
                </Button>
            </Form>
        )}

    </Formik>);

    const renderRedirect = () => (<Redirect to="/confirm" />);

    return (<div>
        {allDetailsEntered ? renderRedirect() : renderForm()}
    </div>)
}

const mapStateToProps = (state: any): StateProps => {
    return {
        donor: state.donor,
        uiState: state.uiState
    };
}

const mapDispatchToProps = (dispatch: any): DispatchProps => {
    return {
        updateDonorDetails: (donorDetails: DonorDetails) => dispatch(updateDonorDetails(donorDetails)),
        updateEventGroupName: (eventGroupName: string) => dispatch(setEventGroupName(eventGroupName)),
        updateSevaDetailsForEvent: (
            sevaCount: number,
            customFieldValues: CustomFieldValue[],
            event: Event) =>
            dispatch(updateSevaDetailsForEvent(sevaCount, customFieldValues, event))
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(NilkanthVarniMurtiPratistha);