import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { Button, Card } from "react-bootstrap";

export default function ReportsLogin() {

    const { loginWithRedirect } = useAuth0();

    return (<Card className="text-center">
        <Card.Header>Reports</Card.Header>
        <Card.Body>
            <Card.Text>
                Please click Login to view the reports
            </Card.Text>
            <Button variant="primary" onClick={() => loginWithRedirect()}>Login</Button>
        </Card.Body>
    </Card>);
}