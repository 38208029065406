//@ts-nocheck
import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import scriptLoader from "react-async-script-loader";
import CreateOrderRequest from "./CreateOrderIdRequest";
import Axios from "axios";
import { CreateOrderIdResponse } from "./CreateOrderIdResponse";
import { apiBaseUrl, paypalAppClientIdDev, paypalAppClientIdProd } from "./AppSettings";
import { Jumbotron, Alert, Spinner } from "react-bootstrap";
import { Link } from "react-router-dom";
import DonationForEvent from "./store/actions/DonationForEvent";
import DonorDetails from "./store/actions/DonorDetails";

const CLIENT_ID =
    process.env.NODE_ENV === "production" ? paypalAppClientIdProd : paypalAppClientIdDev;

interface Props {
    isScriptLoaded: boolean,
    isScriptLoadSucceed: boolean,
    donorDetails: DonorDetails,
    donationItems: Array<DonationForEvent>,
    eventGroupName: string
}

function PaypalButton(props: Props) {

    const [paymentSuccessful, setPaymentSuccessful] = React.useState<boolean | null>(null);
    const [paymentError, setPaymentError] = React.useState<boolean | null>(null);
    const [orderId, setOrderId] = React.useState<string>(null);
    const [paymentInProgress, setPaymentInProgress] = React.useState<boolean>(false);

    useEffect(() => {
        window.React = React;
        window.ReactDOM = ReactDOM;
    }, []);

    const onCreateOrder = async () => {

        const request: CreateOrderRequest = {
            donorEmail: props.donorDetails.email,
            donorFirstName: props.donorDetails.firstName,
            donorLastName: props.donorDetails.lastName,
            donorMobile: props.donorDetails.mobile,
            donationItems: props.donationItems,
            center: parseInt(props.donorDetails.centre)
        };

        setPaymentInProgress(true);

        try {
            const response = await Axios.post<CreateOrderIdResponse>(`${apiBaseUrl}/api/PaypalOrder/create-order`, request);
            setOrderId(response.data.id);
            return response.data.id;
        }
        catch (ex) {
            setPaymentInProgress(false);
            setPaymentError(true);
        }
    }

    const onApproveOrder = async (data) => {

        setPaymentInProgress(true);

        try {
            await Axios.post(`${apiBaseUrl}/api/PaypalOrder/capture-order`, data);
            setPaymentSuccessful(true);
        } catch (ex) {
            setPaymentInProgress(false);
            setPaymentError(true);
        }
    }

    const renderPayButton = () => {

        const PaypalButton = window.paypal.Buttons.driver("react", { React, ReactDOM });

        return (
            <div>
                {paymentInProgress ? (renderPaymentInProgress()) : (<React.Fragment />)}
                <PaypalButton
                    createOrder={onCreateOrder}
                    onApprove={onApproveOrder}>
                </PaypalButton>
            </div>)
    }

    const renderLoading = () => {
        return (<div>Loading Paypal button</div>);
    }

    const renderPaymentSuccessful = () => {
        return (<div>
            <Jumbotron>
                <h1>Jai Swaminarayan {props.donorDetails.firstName},</h1>
                <p>
                    Thank you for the payment. Your payment request is successfully processed. You will receive the receipt of your payment from Paypal shortly.
                </p>
                <p>
                    <a href={`/${props.eventGroupName}`} className="btn btn-primary btn-lg">Return</a>
                </p>
            </Jumbotron>
        </div>)
    }

    const renderPaymentError = () => {
        return (<div>
            <Jumbotron>
                <Alert key="paymentError" variant="danger">
                    <h1>Something went wrong with the transaction - Please note following number {orderId}</h1>
                </Alert>
                <p>
                    <Link to={`/${props.eventGroupName}`} className="btn btn-danger">Return</Link>
                </p>
            </Jumbotron>
        </div>)
    }

    const renderPaymentInProgress = () => {
        return (<div>
            <Alert key="paymentInProgress" variant="primary">
                <h3>Please wait while payment is being processed. Do not press Paypal button again. <Spinner animation="grow" /></h3>
            </Alert>
        </div>)
    }

    const renderUi = () => {
        if (paymentSuccessful)
            return renderPaymentSuccessful();

        if (paymentError)
            return renderPaymentError();

        if (props.isScriptLoadSucceed && props.isScriptLoadSucceed)
            return renderPayButton();

        return renderLoading();
    }

    return (<div>
        {renderUi()}
    </div>)
}

export default scriptLoader(`https://www.paypal.com/sdk/js?client-id=${CLIENT_ID}&currency=AUD`)(PaypalButton);