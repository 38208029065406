import React from "react";
import { Row, Form, Col, Badge } from "react-bootstrap";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export interface MahotsabhaRendererProps {
    description: string;
    renderDescription: boolean;
}

export default function MahotsavSabhaRenderer(props: MahotsabhaRendererProps & 
                                                     DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(0);

    const onSelectedCountChanged = (updatedCount: number) => {
        setSelectedSevaCount(updatedCount);
        raiseUpdatedEvent(updatedCount);
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event);
    }

    const toggleSevaSelection = () => {
        if(selectedSevaCount === 1)
            onSelectedCountChanged(0);
        else
            onSelectedCountChanged(1);
    }
 
    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderDescription = () => {
        return props.renderDescription ? (<Row>
            <Col md={12} style={{textAlign:"center"}} className="mb-1">
                <h2><Badge variant="warning">{props.description}</Badge></h2>
            </Col>
        </Row>) : (<React.Fragment />);
    }

    return (<div style={{ marginTop: "15px" }}>
         {renderDescription()}
         <Row>
            <Col md={8} sm={12}>{props.event.name}</Col>
            <Col md={1} sm={12}>
                <Form.Group>
                    <Form.Check type="checkbox"
                                style={{height:"15px", width: "15px"}} 
                                checked={selectedSevaCount === 1} 
                                onChange={toggleSevaSelection}/>
                </Form.Group>
            </Col>
            <Col md={3} sm={12}>
                {renderTotalSevaAmount()}
            </Col>
        </Row>
    </div>)
}