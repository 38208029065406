import React from "react";
import DonorDetails from "../../store/actions/DonorDetails";
import InstalmentRenderer from "./InstalmentRenderer";

export interface AksharPurushottamMaharajMurtiSevaProps {
    donor: DonorDetails,
    eventGroup: string,
}

export default function AksharPurushottamMaharajMurtiSeva(props: AksharPurushottamMaharajMurtiSevaProps) {

    return (
        <InstalmentRenderer donor={props.donor} eventGroup={props.eventGroup} eventName="Shri Akshar-Purushottam Maharaj Abhishek Murti Yajman Seva (instalments)">
            <div className="justify-content-md-center" style={{ marginBottom: "15px", fontSize: "20px" }}>
                <span>
                If you want to set an Instalement 
                <b>(for Shri Akshar-Purushottam Maharaj Abhishek Murti Mukhya Yaman Seva donation ONLY),</b>
                 please click on save my details below and kayrkar will contact you to set your instalments.
                </span>
            </div>
        </InstalmentRenderer>
    )
}

