import React from "react";
import { Card, Container, Row, Form, Col, Badge, Button } from "react-bootstrap";
import Event from "./models/Event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import CustomFieldValue from "./models/CustomFieldValue";
import CustomField from "./models/CustomField";

export interface DonationEventRendererProps {
    event: Event,
    updateSevaDetailsForEvent: (
        sevaCount: number,
        customFields: Array<CustomFieldValue>,
        event: Event,
        sevaAmount?: number) => void;
    amountLabel?: string,
    selectCountLabel?: string,
    sevaCountMultiplier?: number,
    minDonationAmount? : number
}

export default function DonationEventRenderer(props: DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(-1);
    const [selectedDates, setSelectedDates] = React.useState<Array<Date>>([]);

    const onSelectedCountChanged = (event: any) => {
        const parsedSevaCount = parseInt(event.target.value);
        setSelectedSevaCount(parsedSevaCount);
        raiseUpdatedEvent(parsedSevaCount, selectedDates);
    }

    const selectedDateChanged = (index: number, updatedSelectedDate: Date) => {
        const copyOfDatesState = [...selectedDates];

        if (index < copyOfDatesState.length)
            copyOfDatesState[index] = updatedSelectedDate;
        else
            copyOfDatesState.push(updatedSelectedDate);

        setSelectedDates(copyOfDatesState);

        raiseUpdatedEvent(selectedSevaCount, copyOfDatesState);
    }

    const raiseUpdatedEvent = (sevaCount: number, selectedDates: Date[]) => {
        const customFieldValues: CustomFieldValue[] = [];

        if (selectedDates && selectedDates.length > 0) {
            const dateString = convertDateToString(selectedDates);
            customFieldValues.push({
                customFieldId: props.event.customFields[0].id,
                customFieldValue: dateString
            });
        }

        props.updateSevaDetailsForEvent(
            sevaCount,
            customFieldValues,
            props.event);
    }

    const convertDateToString = (dates: Date[]) => {

        const dateStringValue = [];

        for(let index = 0; index < dates.length; index++)
        {
            if(dates[index])
                dateStringValue.push(`${dates[index].getDate()}-${dates[index].getMonth() + 1}-${dates[index].getFullYear()}`);
        }

        return dateStringValue.toString();
    }

    const onAddDateClick = () => {
        const copyOfDateState = [...selectedDates];
        copyOfDateState.length++;
        setSelectedDates(copyOfDateState);
    }

    const renderSevaCount = () => {
        const sevaCountOptionElement: JSX.Element[] = [];
        const parsedSevaCountMultiplier = props.sevaCountMultiplier ?? 1;

        for (let counter = 1; counter <= props.event.maxCount; counter++) {
            const dropdownValue = counter * parsedSevaCountMultiplier;
            sevaCountOptionElement.push((<option key={`SevaCount-${counter}`} value={dropdownValue}>{dropdownValue}</option>))
        }

        return sevaCountOptionElement;
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderEventImage = () => {
        if (props.event.eventGroupName === "music-inst")
            return (<img style={{ width: "100%" }} src="music-inst-info.jpeg" />);

        if (props.event.eventGroupName === "canvas-murti")
            return (<img style={{ width: "75%" }} src={`murti/${props.event.name}.jpg`} />);

        if (props.event.eventGroupName === "music-instruments")
            return (<img style={{ width: "75%" }} src={`music-instruments/${props.event.name}.jpg`} />);

        if (props.event.eventGroupName === "keyboard-purchase")
            return (<img style={{ width: "75%" }} src={`keyboard-purchase/${props.event.name}.jpg`} />);

        if (props.event.eventGroupName === "dhatu-murti" ||
            props.event.eventGroupName === "dhatu-murti-2024" )
            return (<img style={{ width: "75%" }} src={`dhatu-murti/murti.jpeg`} />);

        if (props.event.name === "Ravisabha 2022 Seva ")
            return (<img style={{ width: "100%" }} src={`ravi-sabha.jpeg`} />);

        return <React.Fragment />
    }

    const renderDateControls = () => {
        const dateControls: JSX.Element[] = [];

        for (var index = 0; index < selectedDates.length; index++) {
            const elementIndex = index;
            dateControls.push(<Col md={3} sm={12} className="mb-1">
                <DatePicker className="form-control"
                    dateFormat="dd-MM-yyyy"
                    selected={selectedDates[index]}
                    onChange={(date: Date) => selectedDateChanged(elementIndex, date)} />
            </Col>);
        }

        return dateControls;
    }

    const renderCustomFields = () => {
        if (!props.event.customFields ||
            props.event.customFields.length == 0 ||
            !selectedSevaCount ||
            selectedSevaCount <= 0)
            return (<React.Fragment />);

        const customFieldToRender = props.event.customFields[0];

        return (<Col sm={12} md={12}>
            <Form>
                <Form.Group>
                    <Form.Label>{customFieldToRender.label}</Form.Label><br />
                    <Row>
                        {renderDateControls()}
                    </Row>
                </Form.Group>
                {selectedDates.length >= selectedSevaCount ? 
                    (<React.Fragment />) :
                    (<Button variant="primary" 
                            type="button" 
                            onClick={onAddDateClick}>Add Date</Button>) }
            </Form>
        </Col>)
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5"  className="badge-warning">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title></Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>{props.amountLabel ?? "Amount"}</Form.Label>
                                <Form.Control type="text" style={{fontWeight: "bold", background: "none"}}
                                    value={`$${props.event.sevaAmount}`}
                                    readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>{props.selectCountLabel ?? "Select the count"}</Form.Label>
                                <Form.Control as="select"
                                    value={selectedSevaCount}
                                    onChange={onSelectedCountChanged}>
                                    <option value={-1}>Select count</option>
                                    {renderSevaCount()}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row>
                        {renderCustomFields()}
                    </Row>
                    <Row className="justify-content-md-center">
                        <Col md={8}>
                            {renderEventImage()}
                        </Col>
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}