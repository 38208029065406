import EventGroup from "../../models/EventGroup";
import { EventGroupsActions, LOAD_EVENTGROUPS_START, LOAD_EVENTGROUPS_SUCCESS } from "../actions/EventGroupsAction";

export interface EventGroupsReducerState {
    eventGroups: Array<EventGroup>
}

const defaultState: EventGroupsReducerState = {
    eventGroups: []
}

export const EventGroupsReducer = (state = defaultState, action: EventGroupsActions): EventGroupsReducerState => {
    
    switch(action.type) {
        case LOAD_EVENTGROUPS_START: {
            return {
                eventGroups: []
            };
        }
        case LOAD_EVENTGROUPS_SUCCESS: {
            const updatedState = [...action.eventGroups];
            return {
                eventGroups: updatedState
            };
        }
    }
    
    return state;
}