import Report from "../../models/Report";
import { LOAD_REPORT, LOAD_REPORT_SUCCESS, ReportActions } from "../actions/ReportAction";

export interface ReportState {
    eventIds: number[];
    report: Report | null;
}

const defaultState: ReportState = {
    eventIds: [],
    report: null
}

export const ReportReducer = (state = defaultState, action: ReportActions): ReportState => {

    switch(action.type)  {
        case LOAD_REPORT: {
            return {
                eventIds: action.eventIds,
                report: null
            };
        }
        case LOAD_REPORT_SUCCESS: {
            return {
                eventIds: action.eventIds,
                report: action.report
            };
        }
    }

    return state;
}