import React from "react";
import { Card, Container, Row, Form, Col, Badge } from "react-bootstrap";
import "react-datepicker/dist/react-datepicker.css";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export default function AksharDiaryEventRenderer(props: DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(-1);

    const onSelectedCountChanged = (event: any) => {
        const parsedSevaCount = parseInt(event.target.value);
        setSelectedSevaCount(parsedSevaCount);
        raiseUpdatedEvent(parsedSevaCount);
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event);
    }

    const renderSevaCount = () => {
        const sevaCountOptionElement: JSX.Element[] = [];

        for (let counter = 1; counter <= props.event.maxCount; counter++) {
            sevaCountOptionElement.push((<option key={`SevaCount-${counter}`} value={counter}>{counter}</option>))
        }

        return sevaCountOptionElement;
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderEventImage = () => {
        if (props.event.name.indexOf("Small") !== -1)
            return (<Col md={{ span: 6, offset: 3 }} sm={12}><img style={{ width: "50%" }} src="akshar-diary/small.jpg" /></Col>);

        if (props.event.name.indexOf("Medium") !== -1)
            return (<Col md={{ span: 6, offset: 3 }} sm={12}><img style={{ width: "50%" }} src="akshar-diary/medium.jpg" /></Col>);

        if (props.event.name.indexOf("Executive") !== -1)
            return (<Row>
                <Col sm={12} md={6}>
                    <div>
                        <img style={{ width: "100%" }} src="akshar-diary/box-design.jpg" />
                        <p style={{ textAlign: "center" }}>Box Design</p>
                    </div>
                </Col>
                <Col sm={12} md={6}>
                    <div>
                        <img style={{ width: "100%" }} src="akshar-diary/cover-page-design.jpg" />
                        <p style={{ textAlign: "center" }}>Cover</p>
                    </div>
                </Col>
            </Row>);

        return <React.Fragment />
    }

    return (<div style={{ marginTop: "15px" }}>
        <Card>
            <Card.Header as="h5">
                {props.event.name}
                <div style={{ float: "right", marginRight: "10px" }}>
                    {renderTotalSevaAmount()}
                </div>
            </Card.Header>
            <Card.Body>
                <Card.Title>{props.event.description}</Card.Title>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Amount</Form.Label>
                                <Form.Control type="text"
                                    value={`$${props.event.sevaAmount}`}
                                    readOnly={true} />
                            </Form.Group>
                        </Col>
                        <Col sm={12} md={6}>
                            <Form.Group>
                                <Form.Label>Select the count</Form.Label>
                                <Form.Control as="select"
                                    value={selectedSevaCount}
                                    onChange={onSelectedCountChanged}>
                                    <option value={-1}>Select count</option>
                                    {renderSevaCount()}
                                </Form.Control>
                            </Form.Group>
                        </Col>
                    </Row>
                    <Row className="justify-content-md-center">
                        {renderEventImage()}
                    </Row>
                </Container>
            </Card.Body>
        </Card>
    </div>)
}