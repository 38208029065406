import React from "react";
import { Row, Form, Col, Badge, Container, Card, Accordion, Button, Alert } from "react-bootstrap";
import { DonationEventRendererProps } from "../DonationEventRenderer";

export interface MahotsabhaRendererProps {
    description: string;
}

export default function GharMandirEventRenderer(props: MahotsabhaRendererProps &
    DonationEventRendererProps) {

    const [selectedSevaCount, setSelectedSevaCount] = React.useState<number>(0);

    const onSelectedCountChanged = (updatedCount: number) => {
        setSelectedSevaCount(updatedCount);
        raiseUpdatedEvent(updatedCount);
    }

    const raiseUpdatedEvent = (sevaCount: number) => {
        props.updateSevaDetailsForEvent(
            sevaCount,
            [],
            props.event);
    }

    const toggleSevaSelection = () => {
        if (selectedSevaCount === 1)
            onSelectedCountChanged(0);
        else
            onSelectedCountChanged(1);
    }

    const renderTotalSevaAmount = () => {
        if (!selectedSevaCount || selectedSevaCount <= 0)
            return (<React.Fragment />);

        const totalSevaAmount = props.event.sevaAmount * selectedSevaCount;
        return (<h5>
            <Badge variant="primary">${totalSevaAmount}</Badge>
        </h5>)
    }

    const renderEventImage = () => {
        return (<img style={{ width: "80%" }} src={`ghar-mandir/${props.event.name}.jpeg`} />);
    }

    const renderDescription = () => {
        if(!props.event.description)
            return (<React.Fragment/>);
        
        const descriptionPoints: string[] = JSON.parse(props.event.description);
        return descriptionPoints.map(r => (<li>{r}</li>));
    }

    return (<div style={{ marginTop: "15px" }}>
        <Accordion>
            <Card>
                <Card.Header as="h5">
                    <Accordion.Toggle as={Button} variant="link" eventKey={`event-${props.event.id}`}>
                        {props.event.name}  <span style={{fontWeight:"bold"}}> [Click Here To Expand] </span>
                    </Accordion.Toggle>
                    <div style={{ float: "right", marginRight: "10px" }}>
                            {renderTotalSevaAmount()}
                        </div>
                </Card.Header>
                <Accordion.Collapse eventKey={`event-${props.event.id}`}>
                    <Card.Body>
                        <Card.Title>
                            <Alert variant="danger">
                                <ul>
                                    {renderDescription()}
                                </ul>
                            </Alert>
                        </Card.Title>
                        <Container>
                            <Row>
                                <Col sm={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>Amount</Form.Label>
                                        <Form.Control type="text"
                                            value={`$${props.event.sevaAmount}`}
                                            readOnly={true} />
                                    </Form.Group>
                                </Col>
                                <Col sm={12} md={6}>
                                    <Form.Group>
                                        <Form.Label>Check to Select the Mandir</Form.Label>
                                        <Form.Check type="checkbox"
                                            style={{ height: "15px", width: "15px" }}
                                            checked={selectedSevaCount === 1}
                                            onChange={toggleSevaSelection} />
                                    </Form.Group>
                                </Col>
                            </Row>
                            <Row className="justify-content-md-center">
                                {renderEventImage()}
                            </Row>
                        </Container>
                    </Card.Body>
                </Accordion.Collapse>
            </Card>
        </Accordion>
    </div>)
}