import Axios, { AxiosRequestConfig } from "axios";
import { apiBaseUrl } from "../../AppSettings";
import Report from "../../models/Report";

export const LOAD_REPORT = "LOAD_REPORT";
export const LOAD_REPORT_SUCCESS = "LOAD_REPORT_SUCCESS";
export const LOAD_REPORT_ERROR = "LOAD_REPORT_ERROR";

export interface LoadReportStart {
    type: typeof LOAD_REPORT;
    eventIds: number[];
}

export interface LoadReportSuccess {
    type: typeof LOAD_REPORT_SUCCESS;
    report: Report;
    eventIds: number[];
}

export interface LoadReportError {
    type: typeof LOAD_REPORT_ERROR;
    error: string;
    eventIds: number[];
}

export type ReportActions = LoadReportSuccess | LoadReportStart | LoadReportError;

const createLoadReportStart = (eventIds: number[]) : LoadReportStart => {
    return {
        type: LOAD_REPORT,
        eventIds: eventIds
    }
}

const createLoadReportSuccess = (eventIds: number[], report: Report) : LoadReportSuccess => {
    return {
        type: LOAD_REPORT_SUCCESS,
        eventIds: eventIds,
        report
    }
}

const createLoadReportError = (eventIds: number[], error: string): LoadReportError => {
    return {
        type: LOAD_REPORT_ERROR,
        eventIds,
        error
    }                                                                                                                                                                                               
}

export const loadReport = (token: string, eventIds: number[]) => async (dispatch: any) => {
    dispatch(createLoadReportStart(eventIds));

    const requestConfig: AxiosRequestConfig = {};
    requestConfig.headers = { 'Authorization': `Bearer ${token}` };

    const response = await Axios.post<Report>(`${apiBaseUrl}/api/admin/report`, eventIds, requestConfig);
    dispatch(createLoadReportSuccess(eventIds, response.data));
}